/*eslint promise/prefer-await-to-then: 0 */

import React from 'react';
import { gql, useLogout, useMutation, useSearchParams, useTranslation } from '@eduvision/toolkit';

const SSOLoginView = (): JSX.Element => {
	const logout = useLogout();
	const [searchParams] = useSearchParams();
	const sessionId = searchParams.get('sid') || '';
	const { t } = useTranslation();

	const [ssoLogout] = useMutation(gql`
		mutation ssoLogin($sessionId: String!) {
			ssoLogout(sessionId: $sessionId) {
				success
			}
		}
	`);
	const doLogout = async () => {
		//This needs to be in a separate function because you can't use await in useEffect
		if (sessionId) {
			await ssoLogout({ variables: { sessionId } });
		}
		logout();
	};
	React.useEffect(() => {
		void doLogout();
	}, [logout, sessionId]);

	return <>{t('SSOLogoutView.loading')}</>;
};

export default SSOLoginView;
