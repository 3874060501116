import { Customization } from '@eduvision/toolkit';

export const DataPrivacyView: Customization['DataPrivacyView'] = {
	textContent: `
**LETZTE AKTUALISIERUNG: 31. März 2023** 



**BILDUNGSFREAKS DATENSCHUTZERKLÄRUNG** 



visionYOU GmbH (das " **Unternehmen**", " **wir**" oder " **uns**") möchte Sie darüber informieren, wie wir Personenbezogene Daten erheben, verwenden und weitergeben. Diese Datenschutzerklärung beschreibt unsere Aktivitäten und Verfahren in Bezug auf Ihre Personenbezogenen Daten, die wir über die folgenden Dienste erheben und verwenden: 



- Über die von uns betriebene Website, von der aus Sie auf diese Datenschutzerklärung zugreifen (die " **Website**"), 

- Über unsere Softwareanwendungen, die von uns zur Nutzung auf Computern und mobilen Geräten zur Verfügung gestellt werden (die " **Apps**"), 

- Über unsere Seiten auf sozialen Medien wie Facebook, Twitter und Instagram (zusammen die " **Social-Media-Seiten**") 

- Über E-Mail-Nachrichten und andere Kommunikation mit Ihnen, und 

- Über andere Offline-Interaktionen, die wir mit Ihnen haben. 



Zusammenfassend bezeichnen wir die Website, Apps, Social-Media-Seiten, Kommunikation und sonstigen Offline-Interaktionen als " **Dienste**". 



**PERSONENBEZOGENE DATEN** 



" **Personenbezogene Daten**" sind Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Über die Dienste erheben und verwenden wir Personenbezogene Daten wie folgt: 



- Name und Kontaktdaten: z. B. Vor- und Nachname, E-Mail-Adresse. 

- Kontaktdaten: z. B. Schulname, E-Mail-Adresse, Telefonnummer und Anschrift der Schule. 

- Accountinformationen: z. B. Nutzername, Nutzergruppe (z.B. Schüler*in, Eltern, Lehrer*in). 

- Rechnungsdaten: z. B. Debit- oder Kreditkartendaten, Kontodaten, Karteninhaber, Rechnungsadresse. 

- Benutzerinhalte: z. B. Bewertungen unserer Produkte und anderer Inhalte, die Sie auf unseren Diensten teilen, einschließlich Beiträgen auf unseren Social-Media-Seiten, Blogs und Kommentarspalten. 

- Informationen zur Geschäftsbeziehung: Einzelheiten zu Ihrer Kommunikation mit uns sowie Einzelheiten zu Ihren Ansprüchen, Beschwerden und Anfragen. 

- Transaktionsinformationen: z. B. Einzelheiten zu Bildungsinhalten und Abonnements, die Sie von uns erworben haben. 

- Geräteinformationen: z. B. Informationen über Ihre Geräte und Nutzung unserer Dienste. Dazu gehören Daten, die durch Cookies und ähnliche Technologien erhoben werden, wie z. B. Ihre IP-Adresse. 

- Social-Media-Informationen: Wie z. B. Profilbilder, Social-Media-Konto-ID und andere Social-Media-Profilinformationen, einschließlich Listen von Freunden/Followern in sozialen Medien. 



**ERHEBUNG VON PERSONENBEZOGENEN DATEN** 



Wir und unsere Dienstleister erheben Personenbezogene Daten auf verschiedene Weise, unter anderem, wenn Sie unsere Dienste nutzen, z. B., wenn Sie ein Konto für den Zugriff auf die Dienste registrieren,unsere Bildungsinhalte aufrufen oder herunterladen, eine Demo anfordern, sich für unseren Newsletter anmelden, an einer unserer Veranstaltungen teilnehmen, den Kundendienst kontaktieren oder einen Kauf tätigen oder mit uns Kontakt aufnehmen. 



Wir müssen Personenbezogene Daten erheben, um Ihnen die angeforderten Dienste bereitstellen zu können. Wenn Sie die erforderlichen Informationen nicht zur Verfügung stellen, sind wir möglicherweise nicht in der Lage, die Dienste bereitzustellen. Wenn Sie uns oder unseren Dienstleistern in Verbindung mit den Diensten Personenbezogene Daten über andere Personen mitteilen, versichern Sie, dass Sie hierzu berechtigt sind und darüber hinaus autorisiert sind, uns zu erlauben, die Informationen in Übereinstimmung mit dieser Datenerklärung zu verwenden. 



**VERARBEITUNGSZWECKE** 



Wir und unsere Dienstleister verwenden Personenbezogene Daten für die folgenden Zwecke: 



- **Bereitstellung der Dienste** 

- **Verarbeitungsaktivitäten - Wir verarbeiten Ihre Personenbezogenen Daten:** 

- um Ihnen die Funktionalität unserer Dienste zur Verfügung zu stellen, wie etwa die Ermöglichung des Zugriffs auf Ihr registriertes Nutzerkonto bei bildungsfreaks und die Zurverfügungstellung unserer Bildungsinhalte. 

- um Ihre Transaktionen abzuschließen, Ihre Kontaktinformationen (wie etwa ihre E-Mail-Adresse) zu verifizieren und Ihnen unseren Kundenservice zur Verfügung zu stellen. 

- um Ihre Buchung von bestimmten Bildungsinhalten und Abonnements durchzuführen und abzurechnen; 

- um Ihnen die Möglichkeit zu geben, im Rahmen der Teilen-Funktion einer anderen Person (etwa Ihren Schüler*innen oder Kindern) unsere interaktiven Bildungsinhalte zur Verfügung zu stellen, wenn Sie dies wünschen. 

- **Kategorien Personenbezogener Daten** 

- Name und Kontaktdaten; dienstliche Kontaktdaten; Accountinformationen; Rechnungsdaten; Informationen zur Geschäftsbeziehung; Transaktionsinformationen; Geräteinformationen. 

- **Rechtsgrundlage** 

- Erfüllung eines Vertrages, einschließlich der Allgemeinen Geschäftsbedingungen oder jeder anderen Vereinbarung, die wir mit Ihnen zur Erbringung der Dienste schließen. 



- **Mitteilung wichtiger Änderungen** 

- **Verarbeitungsaktivitäten – Wir verarbeiten Ihre Personenbezogenen Daten:** 

- um Ihnen administrative Informationen zukommen zu lassen, wie z. B. Änderungen an unseren Allgemeinen Geschäftsbedingungen, Richtlinien und Verfahren. 

- **Kategorien Personenbezogener Daten** 

- Name und Kontaktdaten; dienstliche Kontaktdaten; Accountinformationen; Informationen zur Geschäftsbeziehung; Transaktionsinformationen; Geräteinformationen. 

- **Rechtsgrundlage** 

- Berechtigte Interessen wie etwa unser berechtigtes Interesse daran, dass unsere Dienste von Ihnen im Einklang mit unseren Allgemeinen Geschäftsbedingungen genutzt werden. 

- Erfüllung eines Vertrages, einschließlich der Allgemeinen Geschäftsbedingungen oder jeder anderen Vereinbarung, die wir mit Ihnen zur Erbringung der Dienste schließen. 

- Gesetzliche Verpflichtung, etwa um Ihnen wesentliche Änderungen an unseren Allgemeinen Geschäftsbedingungen oder unsere Datenschutzerklärung mitzuteilen. 



- **Schüler-Links** 

- **Verarbeitungsaktivitäten – Wir verarbeiten Ihre Personenbezogenen Daten:** 

- um Ihnen die von Lehrer*innen und Eltern über Schüler-Links geteilten Bildungsinhalte zur Verfügung zu stellen. 

- um Lehrer*innen darüber zu informieren, wie viele Schüler*innen einen geteilten Schüler-Link aktiviert haben. 

- **Kategorien Personenbezogener Daten** 

- IP-Adresse, Nutzerkürzel 

- **Rechtsgrundlage** 

- Erfüllung eines Vertrages, einschließlich der Allgemeinen Geschäftsbedingungen oder jeder anderen Vereinbarung, die wir mit Ihnen zur Erbringung der Dienste schließen. 



- **Kundenservice/ Bearbeitung Ihrer Anfragen** 

- **Verarbeitungstätigkeiten – Wir verarbeiten Ihre Personenbezogenen Daten:** 

- um Ihre Anfragen zu beantworten, wenn Sie uns kontaktieren, z. B. wenn Sie uns Fragen, Vorschläge, Komplimente oder Beschwerden schicken oder wenn Sie ein Angebot oder andere Informationen über unsere Dienste anfordern. 

- **Kategorien Personenbezogener Daten** 

- Name und Kontaktdaten, dienstliche Kontaktdaten, Accountinformationen, Rechnungsdaten, Informationen zur Geschäftsbeziehung, Transaktionsinformationen, Geräteinformationen und Präferenzen. 

- **Rechtsgrundlage** 

- Erfüllung eines Vertrages, einschließlich der Allgemeinen Geschäftsbedingungen oder jeder anderen Vereinbarung, die wir mit Ihnen zur Bereitstellung der Dienste schließen. 

- Berechtigte Interessen, wie z. B. unser Interesse an der Beantwortung von Anfragen oder Beschwerden. 

- Gesetzliche Verpflichtungen, z. B., wenn Sie eine Auskunftsanfrage zu Ihren Personenbezogenen Daten stellen. 



- **Bereitstellung unseres Newsletters** 

- **Verarbeitungstätigkeiten – Wir verarbeiten Ihre Personenbezogenen Daten:** 

- um Ihnen unseren Newsletter zu senden. 

- **Kategorien Personenbezogenen Daten** 

- Name und Kontaktdaten, dienstliche Kontaktdaten. 

- **Rechtsgrundlage** 

- Einwilligung. 



- **Aggregieren und/oder Anonymisieren von Persönlichen Informationen.** 

- **Verarbeitungstätigkeiten:** 

- Wir können Personenbezogene Daten aggregieren und/oder anonymisieren, so dass sie nicht mehr als Personenbezogene Daten betrachtet werden. Wir tun dies, um andere Daten für unsere Zwecke zu generieren, die wir für jeden Zweck verwenden und offenlegen können, da sie Sie oder andere Personen nicht mehr identifizieren. 

- **Kategorien Personenbezogener Daten** 

- Personenbezogene Daten, die für den jeweiligen Geschäftszweck relevant sind. 

- **Rechtsgrundlage** 

- Berechtigte Interessen, wie z. B. die Generierung anderer Daten für unsere Zwecke, die wir für jeden Zweck verwenden und offenlegen dürfen, da sie Sie oder andere Personen nicht mehr identifizieren. 



- **Betrugsprävention und Sicherheit.** 

- **Verarbeitungstätigkeiten – Wir verarbeiten Ihre Personenbezogenen Daten:** 

- Für Audits, um zu überprüfen, dass unsere internen Prozesse wie vorgesehen funktionieren, und um gesetzliche, regulatorische oder vertragliche Anforderungen zu erfüllen; 

- Zu Zwecken der Betrugsprävention und der Überwachung der Betrugssicherheit, z. B. zur Erkennung und Verhinderung von Cyberangriffen oder Versuchen, Identitätsdiebstahl zu begehen. 

- **Kategorien Personenbezogener Daten** 

- Name und Kontaktdaten; dienstliche Kontaktdaten; Accountinformationen; Rechnungsdaten; Geräteinformationen und Transaktionsinformationen. 

- **Rechtsgrundlage** 

- Gesetzliche Verpflichtungen, z. B. zur Erkennung und Verhinderung von Cyberangriffen. 

- Berechtigte Interessen, wie z. B. das Erkennen und/oder Verhindern von betrügerischen Transaktionen. 



**OFFENLEGUNG PERSONENBEZOGENER DATEN** 



Wir geben Personenbezogene Daten an unsere Servicedienstleister weiter, um diesen zu ermöglichen für uns Dienstleistungen zu erbringen. Dazu gehören Anbieter von Dienstleistungen wie Website-Hosting, Datenbank-Hosting, Zahlungsabwicklung, API-Bereitstellung, Traffic-Verteilung, E-Mail-Zustellung. Folgende Serviceanbieter nutzen wir momentan auf unseren Diensten: 



- **Amazon Web Services Tools** 



Zur Bereitstellung der Plattform verwenden wir überwiegend Amazon Web Services. Alle Amazon Services sind dabei in der „region" eu-central-1 (Frankfurt) gehostet. Weitere Informationen zur Datenverarbeitung durch Amazon Web Services finden Sie in der Datenschutzerklärung des Anbieters unter: [https://aws.amazon.com/de/compliance/data-privacy/](https://aws.amazon.com/de/compliance/data-privacy/) 



- **AWS Relational Database Service (RDS)** 



Wir nutzen AWS RDS um unsere Datenbanken zu hosten. Die Daten sind dabei sowohl „at rest" als auch „in transit" verschlüsselt, d.h. die auf den Servern gespeicherten Nutzerdaten sind sowohl auf der Festplatte sowie bei jeder Übertragung verschlüsselt. 



- **AWS Lambda** 



Zur Bereitstellung der API (Application Programming Interface) für bildungsfreaks.de nutzen wir AWS Lambda, eine automatisch skalierende Ausführungsumgebung. 



- **AWS API-Gateway** 



Wir nutzen AWS API-Gateway um eingehenden Traffic zu verteilen. Hier wird sichergestellt, dass bildungsfreaks.de nur über eine sichere https Verbindung zu erreichen ist. 



- **AWS Cloudwatch** 



Wir nutzen Cloudwatch um Logfiles der API zu speichern. 



- **AWS Virtual-Private-Cloud** 



Wir nutzen AWS Virtual Private Cloud um unsere Ressourcen (Datenbank, API) abzusichern und Traffic nur über definierte Zugriffspfade zu erlauben. 



- **AWS Elastic-Compute-Cloud (EC2)** 



Wir nutzen EC2, um Administratoren Zugriff auf die Datenbank zu ermöglichen. Sämtliche Übertragungen sind dabei verschlüsselt. 



- **AWS Simple Email Service (SES) und AWS Simple Storage Service (S3)** 



Wir verwenden SES zum Versenden von transaktionalen E-Mails (z.B. Registrierungsbestätigung) und S3 zum Speichern verschiedener Dateien (u.a. Zertifikate der Nutzer, welche nach Abschluss eines Kurses ausgestellt werden). 



- **Secupay** 



Zur Zahlungsabwicklung verwenden wir den Zahlungsdienst Secupay. Dieser verarbeitet in unserem Auftrag die bei Buchungen von Bildungsinhalten abgefragten Adress-, Zahlungs- und Bestelldaten der Nutzer*innen. Weitere Informationen zum Datenschutz bei Secupay finden Sie in der Datenschutzerklärung des Anbieters unter: [https://secupay.com/datenschutz](https://secupay.com/datenschutz)



- **YouTube** 



In manchen unserer Bildungsinhalte können Videos von YouTube eingebunden werden. YouTube ist ein Videoportal, das durch die YouTube LLC, 901 Cherry Ave., San Brune, CA 94066, USA betrieben wird. Für die Datenverarbeitung im europäischen Raum ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) verantwortlich. Sobald Sie Bildungsinhalte besuchen, die ein Youtube-Video eingebaut haben, setzt YouTube ein Cookie, das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube darüber hinaus Ihre Interaktionen auf unserer Website Ihrem Profil zuordnen. Eine Wiedergabe von YouTube-Videos erfolgt nur, nachdem Sie uns Ihre Einwilligung zur Datenverarbeitung durch YouTube erteilt haben. YouTube verarbeitet Personenbezogene Daten u.a. auch in den USA. Als Grundlage der Datenübermittlung in die USA verwendet YouTube Standardvertragsklauseln. Weitere Informationen zum Datenschutz bei YouTube finden Sie in der Datenschutzerklärung des Anbieters unter: [https://www.google.de/intl/de/policies/privacy/](https://www.google.de/intl/de/policies/privacy/). 



**ANDERE VERWENDUNGEN UND OFFENLEGUNGEN** 



Wir verwenden und geben Ihre Personenbezogenen Daten auch weiter, wenn dies notwendig oder angemessen ist, insbesondere wenn wir dazu gesetzlich verpflichtet sind oder ein berechtigtes Interesse daran haben: 



- **Um geltende Gesetze und Vorschriften einzuhalten.** 



Dies kann auch Gesetze außerhalb des Landes umfassen, in dem Sie wohnen. Solche Gesetze und Vorschriften umfassen zum Beispiel: 



- Zivil- und Handelssachen: wenn wir eine gerichtliche Anordnung zur Offenlegung von Informationen für die Zwecke eines Gerichtsverfahrens erhalten, z. B. gemäß der Verordnung (EU) Nr. 1215/2012 über die gerichtliche Zuständigkeit und die Anerkennung und Vollstreckung von Entscheidungen in Zivil- und Handelssachen. 

- Verbraucherangelegenheiten: zur Erfüllung von Anfragen der zuständigen Behörden gemäß den Verbraucherschutzgesetzen der EU oder der EU-Mitgliedstaaten, z. B. gemäß der Richtlinie (EU) 2019/2161 und den entsprechenden Durchführungsbestimmungen in den EU-Mitgliedstaaten. 

- Unternehmens- und Steuerangelegenheiten: um unseren Verpflichtungen gemäß den geltenden gesellschafts- und steuerrechtlichen Vorschriften in Deutschland nachzukommen wie etwa § 147 AO und § 257 HGB. 

- Compliance und interne Untersuchungen: zur Erfüllung der Anforderungen des Hinweisgeberschutzgesetzes. 



- **Um mit öffentlichen und staatlichen Behörden zu kooperieren.** 



Um auf eine Anfrage zu antworten oder um Informationen bereitzustellen, die wir für notwendig oder angemessen halten, um unseren Verpflichtungen zur Zusammenarbeit mit Behörden nachzukommen, z. B., wenn die zuständigen Datenschutzaufsichtsbehörde Ermittlungen einleitet. Dazu können auch Behörden außerhalb des Landes gehören, in dem Sie wohnen. 



- **Um mit Strafverfolgungsbehörden zu kooperieren.** 



Um Anfragen und Anordnungen von Strafverfolgungsbehörden der EU und der EU-Mitgliedstaaten nachzukommen, Informationen im Zusammenhang mit strafrechtlichen Ermittlungen in Übereinstimmung mit den geltenden lokalen Gesetzen bereitzustellen, oder um Schritte einzuleiten, um Informationen zu melden, die wir für die Strafverfolgung für wichtig halten, wenn dies nach den geltenden lokalen Gesetzen erforderlich oder ratsam ist. 



- **Aus anderen rechtlichen Gründen.** 



- Um unsere Allgemeinen Geschäftsbedingungen durchzusetzen; und 

- Um die Rechte, die Privatsphäre, die Sicherheit oder das Eigentum von uns, von Ihnen oder anderer Personen zu schützen. 



- **In Verbindung mit einem Verkauf oder einer geschäftlichen Transaktion.** 



Wir haben ein berechtigtes Interesse daran, Ihre Personenbezogenen Daten im Falle einer Umstrukturierung, Fusion, eines Verkaufs, eines Joint Ventures, einer Abtretung, eines Transfers oder einer anderen Verfügung über unser gesamtes Geschäft oder unsere Vermögenswerte (einschließlich in Verbindung mit einem Konkurs- oder ähnlichen Verfahren) an einen Dritten weiterzugeben oder zu übertragen. 



**COOKIES** 



Cookies sind Informationen, die direkt auf dem Computer gespeichert werden, den Sie benutzen. Auf unserer Webseite nutzen werden folgende Cookies eingebunden 



- Consent-Cookie: Wir setzen einen persistenten Cookie um zu speichern, ob Sie externen Cookies (z. B. Youtube) zugestimmt haben oder nicht. 

- Authentifizierungs-Cookie: Wir nutzen persistente Cookies zur Authentifizierung von Nutzern und damit wir Sie über den gesamten Aufenthalt auf unserer Website wiedererkennen. 



Diese Cookies sind für die Bereitstellung der Funktionalitäten der Webseite zwingend erforderlich. 



**Verwendung und Offenlegung von Sonstigen Informationen** 



Wir können sonstige Informationen für jeden Zweck verwenden und offenlegen, es sei denn, wir sind nach geltendem Recht zu etwas anderem verpflichtet. Wenn wir nach geltendem Recht verpflichtet sind, sonstige Daten als Personenbezogene Daten zu behandeln, können wir sie für die Zwecke verwenden und offenlegen, für die wir Personenbezogene Daten verwenden und offenlegen, wie in dieser Datenschutzerklärung beschrieben. In einigen Fällen können wir sonstige Informationen mit Personenbezogenen Daten kombinieren. Wenn dies der Fall ist, behandeln wir die kombinierten Informationen als Personenbezogene Daten, solange sie kombiniert sind. 



**SICHERHEIT** 



Wir bemühen uns, angemessene organisatorische, technische und administrative Maßnahmen zu ergreifen, um Personenbezogene Daten innerhalb unseres Unternehmens zu schützen. Leider kann kein Datenübertragungs- oder Speichersystem zu 100 % sicher sein. Wenn Sie Grund zu der Annahme haben, dass Ihre Interaktion mit uns nicht mehr sicher ist, benachrichtigen Sie uns bitte unverzüglich wie im Abschnitt "Kontaktaufnahme" unten beschrieben. 



**IHRE RECHTE** 



Wenn Sie den Zugriff auf bzw. die Auskunft über Ihre Personenbezogenen Daten, deren Berichtigung, Aktualisierung, Unterdrückung, Einschränkung oder Löschung beantragen möchten, der Verarbeitung Ihrer Personenbezogenen Daten widersprechen oder diese ablehnen möchten, Ihre Einwilligung widerrufen möchten (was die Rechtmäßigkeit der Verarbeitung vor dem Widerruf nicht beeinträchtigt) oder wenn Sie eine Kopie Ihrer Personenbezogenen Daten zum Zwecke der Übermittlung an ein anderes Unternehmen anfordern möchten (soweit Ihnen diese Rechte nach geltendem Recht zustehen), können Sie sich wie in dem Abschnitt "Kontaktaufnahme" unten beschrieben an uns wenden. Wir werden Ihre Anfrage im Einklang mit geltendem Recht beantworten. 



Bitte geben Sie in Ihrer Anfrage an, welche Personenbezogenen Daten Sie geändert haben möchten oder ob Sie Ihre Personenbezogenen Daten aus unserer Datenbank löschen lassen möchten. Zu Ihrem Schutz können wir nur Anfragen in Bezug auf die Personenbezogenen Daten umsetzen, die mit der E-Mail-Adresse verbunden sind, die Sie für Ihre Anfrage verwenden, und wir müssen möglicherweise Ihre Identität überprüfen, bevor wir Ihre Anfrage umsetzen. Wir werden versuchen, Ihrer Anfrage so schnell wie möglich nachzukommen. 



Bitte beachten Sie, dass wir unter Umständen bestimmte Informationen zu Buchhaltungszwecken aufbewahren müssen und/oder um Transaktionen abzuschließen, die Sie vor der Beantragung einer Änderung oder Löschung begonnen haben (z. B., wenn Sie ein Abonnement buchen, können Sie die angegebenen Personenbezogenen Daten möglicherweise erst nach Abschluss der Buchung ändern oder löschen lassen). 



**Einreichen einer Beschwerde bei einer Behörde** 



Sie können auch eine Beschwerde bei der Datenschutzbehörde des Bundeslandes einreichen, in dem Sie Ihren gewöhnlichen Wohnsitz oder Arbeitsplatz haben oder in dem ein mutmaßlicher Verstoß gegen geltendes Datenschutzrecht vorliegt. Eine Liste der Datenschutzbehörden finden Sie unter [http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080](http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080). 



**AUFBEWAHRUNGSFRISTEN** 



Wir werden Ihre Personenbezogenen Daten so lange aufbewahren, wie es für die Erfüllung der in dieser Datenschutzerklärung genannten Zwecke erforderlich ist, es sei denn, eine längere Aufbewahrungsfrist ist gesetzlich vorgeschrieben oder zulässig, z. B. zur Erfüllung gesetzlicher, aufsichtsrechtlicher, steuerlicher, buchhalterischer oder Berichterstattungspflichten. 



Die Kriterien für die Festlegung unserer Aufbewahrungsfristen sind unter anderem: 



- der Zeitraum, in dem wir mit Ihnen in einer geschäftlichen Beziehung stehen und Ihnen die Dienste zur Verfügung stellen (z. B. solange Sie ein Konto bei uns haben oder die Dienste nutzen); 

- ob es eine gesetzliche Verpflichtung gibt, der wir unterliegen (z. B. sind wir aufgrund bestimmter Gesetze wie etwa § 147 AO und § 257 HGB verpflichtet, Aufzeichnungen über Ihre Transaktionen aufzubewahren, bevor wir sie löschen können); oder 

- ob die Aufbewahrung aus rechtlichen Gründen empfehlenswert ist (z. B. im Hinblick auf geltende Verjährungsfristen, Rechtsstreitigkeiten oder behördliche Untersuchungen). 



Wenn eine rechtliche Verpflichtung besteht oder die Aufbewahrung aus rechtlichen Gründen empfehlenswert ist, werden wir unter bestimmten Umständen bestimmte Personenbezogene Daten aufbewahren, auch nachdem Ihr Konto gelöscht wurde und/oder wir Ihnen die Dienste nicht mehr zur Verfügung stellen, zum Beispiel: 



- Um mit Strafverfolgungsbehörden oder anderen staatlichen Behörden zu kooperieren: Wenn wir eine Verwahrungsanordnung oder einen Durchsuchungsbefehl in Bezug auf Ihr Konto in unserem Dienst erhalten, bewahren wir die Personenbezogenen Daten, die Gegenstand dieser Anordnung oder dieses Durchsuchungsbefehls sind, auf, nachdem Sie Ihr Konto in unserem Dienst gelöscht haben. 

- Zur Einhaltung gesetzlicher Bestimmungen zu Steuern und Buchhaltung: Wir sind berechtigt, Ihre Personenbezogenen Daten wie Rechnungsdaten, Geschäftshistorie und/oder Transaktionsdaten 6 bzw. 10 Jahre nach der Löschung Ihres Dienstkontos aufzubewahren, um steuerrechtlichen und buchhalterischen Anforderungen zu genügen. 

- Zur Verfolgung oder Verteidigung eines Rechtsstreits: Wir können relevante Personenbezogene Daten im Falle eines Rechtsanspruchs oder einer Beschwerde, einschließlich behördlicher Untersuchungen oder rechtlicher Verfahren über einen Anspruch im Zusammenhang mit Ihren Personenbezogenen Daten, oder wenn wir vernünftigerweise annehmen, dass ein solcher Rechtsstreit droht (sei es in Bezug auf unsere Beziehung zu Ihnen oder anderweitig), bis zu 10 Jahre nach der Beilegung des Rechtsstreits oder der Entscheidung eines Gerichts oder Tribunals aufbewahren, gegen die kein weiteres Rechtsmittel möglich ist. 



**DIENSTE VON DRITTEN** 



Diese Datenschutzerklärung bezieht sich nicht auf die Datenschutz-, Informations- oder sonstigen Praktiken Dritter, einschließlich Dritter, die eine Website oder einen Service betreiben, auf die die Dienste verlinken, und wir sind nicht für diese verantwortlich. Die Aufnahme eines Links in die Dienste bedeutet nicht, dass die verlinkte Website oder der verlinkte Service von uns oder unseren verbundenen Unternehmen unterstützt wird. 



Darüber hinaus sind wir nicht verantwortlich für die Informationserfassung, -nutzung, -weitergabe oder Sicherheitsrichtlinien oder -praktiken anderer Organisationen wie Facebook, Apple, Google, Microsoft, RIM oder anderer App-Entwickler, App-Anbieter, Anbieter von Social-Media-Plattformen, Anbieter von Betriebssystemen, Mobilfunkanbietern oder Geräteherstellern, auch nicht in Bezug auf Personenbezogene Daten, die Sie über oder in Verbindung mit unseren Social-Media-Seiten an andere Organisationen weitergeben. 



**SENSIBLE INFORMATIONEN** 



Sofern wir Sie nicht dazu auffordern, bitten wir Sie, uns keine sensiblen Personenbezogenen Daten (z. B. Sozialversicherungsnummern, Informationen in Bezug auf rassische oder ethnische Herkunft, politische Meinungen, Religion oder andere Überzeugungen, Gesundheit, biometrische oder genetische Merkmale, kriminellen Hintergrund oder Gewerkschaftszugehörigkeit) über die Dienste oder auf andere Weise zu übermitteln. 



**ZAHLUNGSDIENSTE VON DRITTEN** 



Die Dienste können Funktionen bereitstellen, die es Ihnen ermöglichen, Zahlungen an das Unternehmen mit Hilfe von Zahlungsdiensten Dritter vorzunehmen, bei denen Sie ein eigenes Konto eingerichtet haben. Wenn Sie einen solchen Zahlungsdienst nutzen, um eine Zahlung an uns zu leisten, werden Ihre Personenbezogenen Daten von diesem Dritten und nicht von uns erfasst und unterliegen den Datenschutzerklärungen des Dritten und nicht der vorliegenden Datenschutzerklärung. Wir haben keine Kontrolle über und sind nicht verantwortlich für die Erfassung, Verwendung und Offenlegung Ihrer Personenbezogenen Daten durch diesen Dritten. 



**AKTUALISIERUNGEN DIESER DATENSCHUTZERKLÄRUNG** 



Die Legende "LETZTE AKTUALISIERUNG" oben in dieser Datenschutzerklärung gibt an, wann diese Datenschutzerklärung zuletzt überarbeitet wurde. Alle Änderungen treten in Kraft, wenn wir die überarbeitete Datenschutzerklärung in den Diensten veröffentlichen. 



**Kontaktaufnahme** 



visionYou GmbH mit Sitz in der Stahndorfer Straße 107, 14482 Potsdam, ist das verantwortliche Unternehmen für die Erhebung, Verwendung und Offenlegung Ihrer Personenbezogenen Daten nach dieser Datenschutzerklärung. 



Wenn die Fragen zu dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte unter [info@visionyou.de](mailto:info@visionyou.de) oder: 

 

visionYOU GmbH 

Stahnsdorfer Str. 107 

14482 Potsdam 

 

Unter [datenschutz@visionyou.de](mailto:datenschutz@visionyou.de) kann unser Datenschutzbeauftragter kontaktiert werden, beispielsweise wenn es um Fragen zu dieser Datenschutzerklärung, zur Datenerfassung und zur Datenverarbeitung geht. 

 

Da die Kommunikation per E-Mail nicht immer sicher ist, bitten wir Sie, keine Kreditkartendaten oder andere sensible Informationen in Ihren E-Mails an uns anzugeben. 

 
 
`,
};
