import React from 'react';
import {
	ContentWrapper,
	ImageHeader,
	SectionWrapper,
	Contents,
	EducationFormatSection,
	SectionHeader,
	CategoriesRow,
	useCurrentUser,
	useRecommendedApiContents,
	useAvailableCollections,
	useTranslation,
} from '@eduvision/toolkit';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';
import teacherImage from '../images/BF_Header_FuerLehrer.png';

const LoggedInUserView = (): JSX.Element | null => {
	const { t } = useTranslation();
	const currentUser = useCurrentUser();

	const progressedContents = React.useMemo(
		() =>
			currentUser?.progressedContents
				.filter((c) => (!c.demo && c.completion !== 1))
				.sort((a, b) => (a.lastProgressedAt! > b.lastProgressedAt! ? -1 : 1)),
		[currentUser?.progressedContents]
	);
	
	const recommendedContents = useRecommendedApiContents(currentUser?.audience?.id);
	const availableCollections = useAvailableCollections(recommendedContents);
	const accessibleProductContents = currentUser?.accessibleProductContents;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teacherImage}
				title={t('LoggedInUserView.header.title', {
					name: currentUser?.userName || currentUser?.firstName || '',
				})}
				description={t('LoggedInUserView.header.description')}
			/>
			{progressedContents?.length ? (
				<SectionWrapper flexDirection="column">
					<Contents
						filterAfterCategoryGroup="EDUCATION_FORMAT"
						title={t('LoggedInUserView.contents.activeCourses.title')}
						subTitle={t('LoggedInUserView.contents.activeCourses.subTitle')}
						buttonLabel={t('LoggedInUserView.contents.activeCourses.buttonLabel')}
						navigateTo="/contents"
						contents={progressedContents}
						showProgress
					/>
				</SectionWrapper>
			) : null}
			{accessibleProductContents?.length ? (
				<SectionWrapper flexDirection="column">
					<Contents
						title={t('LoggedInUserView.contents.purchasedCourses.title')}
						subTitle={t('LoggedInUserView.contents.purchasedCourses.subTitle')}
						contents={accessibleProductContents}
						showProgress
					/>
				</SectionWrapper>
			) : null}

			<SectionWrapper flexDirection="column">
				<Contents
					contents={recommendedContents}
					buttonLabel={t('LoggedInUserView.contents.recommendedCourses.buttonLabel')}
					navigateTo="/contents"
					title={t('LoggedInUserView.contents.recommendedCourses.title')}
					subTitle={t('LoggedInUserView.contents.recommendedCourses.subTitle')}
					maxItems={6}
				/>
			</SectionWrapper>
			{currentUser.audience?.type === 'TEACHER' || currentUser.audience?.type === 'PARENTS' ? (
				<EducationFormatSection />
			) : null}
			<SectionWrapper padding="2rem" altBackground flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title={t('LoggedInUserView.sections.makeItYourThing.title')}
					subTitle={t('LoggedInUserView.sections.makeItYourThing.subTitle')}
				/>
				<CategoriesRow data={availableCollections || undefined} />
			</SectionWrapper>
			{currentUser.audience?.type === 'TEACHER' || currentUser.audience?.type === 'PARENTS' ? (
				<BildungsfreaksFavouritesSection />
			) : null}
		</ContentWrapper>
	);
};

export default LoggedInUserView;
