import React from 'react';
import {
	Grid,
	Button,
	Txt,
	ImageBox,
	SectionWrapper,
	GridItem,
	useCurrentUser
} from '@eduvision/toolkit';
import { Box } from '@mui/material';
import image from '../images/BF_Grafik_Kachel_Abo_Square.png';

const ProBannerSection = () => {
	const currentUser = useCurrentUser();
	const hasProMembership = currentUser?.membership?.level === 2;
	return hasProMembership || currentUser?.audienceType === 'PUPILS' ? null : (
		<SectionWrapper altBackground>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<ImageBox width="100%" src={image} />
				</Grid>
				<GridItem xs={12} sm={6}>
					<Box flexDirection="column" p="2rem">
						<Txt width="100%" variant="h2" fontWeight="bold" m="0 0 1rem">
							Werde zum PRO!
						</Txt>
						<Txt width="100%" m="0 0 1rem">
							Erhalte vollen Zugriff auf alle digitalen Bildungsmaterialien und exklusiven Content!
						</Txt>
						<Button variant="contained" navigateTo="/pricing">
							Jetzt starten
						</Button>
					</Box>
				</GridItem>
			</Grid>
		</SectionWrapper>
	);
};

export default React.memo(ProBannerSection);
