import React from 'react';
import type { ThemeOptions as MuiThemeOptions } from '@eduvision/toolkit';
import { defaultVariables} from '@eduvision/toolkit';


// interface CustomColors {
// 	someColor: string;
// }

// interface CustomVariables {
// 	someVar: string;
// }

// declare module '@eduvision/toolkit' {
// 	interface ButtonPropsVariantOverrides {
// 		customVariant: true;
// 	}
// 	interface Theme {
// 		customColors: CustomColors;
// 		customVariables: CustomVariables;
// 	}
// 	interface ThemeOptions {
// 		customColors?: CustomColors;
// 		customVariables?: CustomVariables;
// 	}
// }

const colors = {
	primary:'#009FE3',
	secondary:'#FFC358',
	textPrimary: '#575655',
};


export const customTheme = {
	// defaultVariables:{
	// 	...defaultVariables,
	// 	borderRadiusL: '30em'
	// },
	palette: {
		primary: {
			main: colors.primary,
			light: '#E6EDFE',
			contrastText: '#ffffff'
		},
		secondary: {
			main: colors.secondary,
		},
		text:{
			primary: colors.textPrimary
		}
	},
	// components:{
	// 	MuiButton: {
	// 		variants: [
	// 			{
	// 				props: { variant: 'customVariant' },
	// 				style: {
	// 					'textTransform': 'uppercase',
	// 					'border': `1px solid black`,
	// 					'color': 'black',
	// 					'backgroundColor': 'black',
	// 					'&:hover': {
	// 						border: `1px solid black`,
	// 						color: 'black',
	// 						backgroundColor: 'black',
	// 					},
	// 				},
	// 			},
	// 		]
	// 	}
	// }
	
	
} as MuiThemeOptions;


