import React from 'react';
import {
	useNavigate,
	useParams,
	Button,
	Dialog,
	AuthWrapper,
	FilledTextField,
	Txt,
	useTextFieldController,
	Grid,
	useTranslation,
} from '@eduvision/toolkit';
import { useAccessGeneratorRegister } from '../mutations/useAccessGeneratorRegister';

const AccessGeneratorRegisterView = (): JSX.Element => {
	const { accessGeneratorCode: accessGeneratorCodeFromRoute } = useParams();
	const { t } = useTranslation();
	// console.log('accessGeneratorCodeFromRoute: ', accessGeneratorCodeFromRoute);
	const navigate = useNavigate();
	const accessGeneratorRegister = useAccessGeneratorRegister();
	const {
		newStateValue: accessGeneratorCode,
		textInputProps: textFieldProps,
		validate: validate,
	} = useTextFieldController({
		defaultValue: accessGeneratorCodeFromRoute,
		inputLabel: t('AccessGeneratorRegisterView.input.label'),
		stateKey: 'accessGeneratorCode',
		validationFunction: (val) =>
			!val ? t('AccessGeneratorRegisterView.input.required') : undefined,
		stateValueFallback: '',
		textFieldType: 'accessGeneratorCode',
	});

	const [loading, setLoading] = React.useState(false);
	const [phrase, setPhrase] = React.useState<string | undefined>(undefined);

	const handleNavigate = React.useCallback(() => {
		Dialog.render({
			title: t('AccessGeneratorRegisterView.loginPhraseDialog.title'),
			description: t('AccessGeneratorRegisterView.loginPhraseDialog.description'),
			buttons: [
				{
					id: '1',
					variant: 'contained',
					label: t('AccessGeneratorRegisterView.loginPhraseDialog.yes'),
					onClick: () => {
						navigate('/phrase-login');
					},
				},
				{
					id: '2',
					label: t('AccessGeneratorRegisterView.loginPhraseDialog.no'),
					onClick: undefined,
				},
			],
		});
	}, [navigate]);

	const handleLogin = React.useCallback(async () => {
		const invalid = validate!();
		if (invalid) {
			return;
		}
		setLoading(true);
		const {
			success,
			error,
			phrase: loginPhrase,
		} = await accessGeneratorRegister(accessGeneratorCode!);
		setLoading(false);
		if (success) {
			setPhrase(loginPhrase);
		} else {
			switch (error) {
				case 'ACCESS_GENERATOR_CODE_INVALID':
					Dialog.render(
						t('AccessGeneratorRegisterView.errors.ACCESS_GENERATOR_CODE_INVALID', {
							returnObjects: true,
						})
					);
					break;
				case 'ACCESS_GENERATOR_CODE_EXPIRED':
					Dialog.render(
						t('AccessGeneratorRegisterView.errors.ACCESS_GENERATOR_CODE_EXPIRED', {
							returnObjects: true,
						})
					);
					break;
				case 'ACCESS_GENERATOR_CODE_EXHAUSTED':
					Dialog.render(
						t('AccessGeneratorRegisterView.errors.ACCESS_GENERATOR_CODE_EXHAUSTED', {
							returnObjects: true,
						})
					);
					break;
				case 'ALREADY_LOGGED_IN':
					Dialog.render(
						t('AccessGeneratorRegisterView.errors.ALREADY_LOGGED_IN', {
							returnObjects: true,
						})
					);
					break;
				case 'NETWORK_ERROR':
					Dialog.render(
						t('AccessGeneratorRegisterView.errors.NETWORK_ERROR', {
							returnObjects: true,
						})
					);
					break;
				default:
					Dialog.render(
						t('AccessGeneratorRegisterView.errors.UNKNOWN_ERROR', {
							returnObjects: true,
						})
					);
			}
		}
	}, [accessGeneratorCode, validate]);

	return !phrase ? (
		<AuthWrapper title={t('AccessGeneratorRegisterView.codeRegister.title')}>
			<Grid item xs={12}>
				<Txt>{t('AccessGeneratorRegisterView.codeRegister.description')}</Txt>
			</Grid>
			<Grid item xs={12}>
				<FilledTextField fullWidth variant="filled" {...textFieldProps} />
			</Grid>
			<Grid item xs={12}>
				<Button fullWidth onClick={handleLogin} variant="contained" loading={loading}>
					{t('AccessGeneratorRegisterView.codeRegister.login')}
				</Button>
			</Grid>
		</AuthWrapper>
	) : (
		<AuthWrapper title={t('AccessGeneratorRegisterView.loginPhrase.title')}>
			<Grid item xs={12}>
				<Txt m="0 0 1rem 0">{t('AccessGeneratorRegisterView.loginPhrase.description')}</Txt>
				<Txt m="0 0 1rem 0" variant="h2">
					{phrase}
				</Txt>
				<Txt m="0 0 1rem 0">{t('AccessGeneratorRegisterView.loginPhrase.save')}</Txt>
			</Grid>
			<Grid item xs={12}>
				<Button fullWidth onClick={handleNavigate} variant="contained" loading={loading}>
					{t('AccessGeneratorRegisterView.loginPhrase.button')}
				</Button>
			</Grid>
		</AuthWrapper>
	);
};

export default AccessGeneratorRegisterView;
