import { Customization } from '@eduvision/toolkit';
import image from '../images/BF_Grafik_Header_Favoriten.png';

export const FavouritesView: Customization['FavouritesView'] = {
	addFavouritesText: `**Du willst dir deine liebsten Kurse merken?** \n\n
[Registriere](/signup) dich oder [melde dich an](/login) um Kurse und Materialien auf deine Favoritenliste zu setzen.
`,
	image,
	imageLabel: 'Deine Favoriten',
};
