import React from 'react';
import {
	SectionHeader,
	useAllCollections,
	SectionWrapper,
	SliderRow,
	SliderRowItem
} from '@eduvision/toolkit';

const favouriteCollectionIds = ['17-diversity', '10-sophie-scholl', '16-berufsorientierung', '11-wissensbissen'];

const BildungsfreaksFavouritesSection = (): JSX.Element => {
	const {data:collections} = useAllCollections();
	const favourites = React.useMemo(
		() =>
			favouriteCollectionIds
				?.map((id) => {
					const collection = collections?.find((c) => c.id === id);

					return collection
						? {
								id: collection?.id,
								imageUrl: collection?.imageUrl,
								navigateTo: `/collection/${collection?.id}`,
						  }
						: undefined;
				})
				.filter(Boolean) as SliderRowItem[],

		[collections]
	);

	return (
		<SectionWrapper flexDirection="column">
			<SectionHeader
				alignItems="center"
				title="bildungsfreaks-Lieblinge"
				subTitle="Entdecke unsere beliebtesten Themen für den digitalen Unterricht."
			/>
			{favourites ? <SliderRow items={favourites} tileMarginRightRemFactor={1} /> : null}
		</SectionWrapper>
	);
};

export default BildungsfreaksFavouritesSection;
