import React from 'react';
import { Helmet } from 'react-helmet';
import {
	ContentWrapper,
	ImageHeader,
	PerksSection,
	useTranslation,
} from '@eduvision/toolkit';
import LearnSmartSection from '../components/LearnSmartSection';
import image from '../images/BF_Grafik_Header_UeberUns.png';
import ProBannerSmallSection from '../components/ProBannerSmallSection';

const AboutUsView = () => {
	const { t } = useTranslation();
	return (
		<ContentWrapper center minHeight="10rem">
			<Helmet>
				<meta name="description" content={t('AboutUsView.meta.description')} />
			</Helmet>
			<ImageHeader src={image} title={t('AboutUsView.header.title')} />
			<LearnSmartSection />
			<PerksSection />
			<ProBannerSmallSection />
		</ContentWrapper>
	);
};

export default AboutUsView;
