import React from 'react';
import {
	useNavigate,
	Button,
	Grid,
	Dialog,
	AuthWrapper,
	FilledTextField,
	useTextFieldController,
	checkValidationFunctions,
	validateEmail,
	validatePassword,
	useFetchUser,
	setToken,
	useLogin,
	useSearchParams,
	useTranslation,
} from '@eduvision/toolkit';
import { Box, Button as MuiButton } from '@mui/material';
import { OIDC_CLIENT_ID, OIDC_ENDPOINT_URL } from '../customization/consts';
import nbpLogo from '../images/Logo-Quadrat-NBP_web.jpg';

const LoginView = (): JSX.Element => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const contentId = searchParams.get('contentId') || undefined;
	const fetchUser = useFetchUser();
	const login = useLogin();

	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: t('LoginView.input.email'),
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: t('LoginView.input.password'),
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const [loading, setLoading] = React.useState(false);

	const handleLogin = React.useCallback(async () => {
		const isValid = checkValidationFunctions([validateEmailFn, validatePasswordFn]);
		if (isValid) {
			setLoading(true);
			const { success, jwt, error } = await login(email!, password!);
			setLoading(false);
			if (success) {
				setToken(jwt!);
				const currentUser = await fetchUser();
				contentId ? navigate(`/content/${contentId}`) : currentUser?.isAdminUser ? navigate('/admin-panel') : navigate('/');
			} else {
				switch (error) {
					case 'USER_NOT_FOUND':
						Dialog.render(t('LoginView.errors.USER_NOT_FOUND', { returnObjects: true }));
						break;
					case 'USER_NOT_VERIFIED':
						Dialog.render(t('LoginView.errors.USER_NOT_VERIFIED', { returnObjects: true }));
						break;
					case 'CREDENTIALS_DO_NOT_MATCH':
						Dialog.render(t('LoginView.errors.CREDENTIALS_DO_NOT_MATCH', { returnObjects: true }));
						break;
					case 'NETWORK_ERROR':
						Dialog.render(t('LoginView.errors.NETWORK_ERROR', { returnObjects: true }));
						break;
					default:
						Dialog.render(t('LoginView.errors.UNKNOWN_ERROR', { returnObjects: true }));
				}
			}
		}
	}, [contentId, email, navigate, password, t, validateEmailFn, validatePasswordFn]);

	const textFieldsProps = React.useMemo(
		() => [emailFieldProps, passwordFieldProps],
		[emailFieldProps, passwordFieldProps]
	);

	const handleSSOLogin = () => {
		window.location.href = `${OIDC_ENDPOINT_URL}/auth?response_type=code&client_id=${OIDC_CLIENT_ID}`;
	}

	return (
		<AuthWrapper title={t('LoginView.title')}>
			<>
				{textFieldsProps.map((textFieldProps,i) => (
					<Grid key={i} item xs={12}>
						<FilledTextField fullWidth variant="filled" {...textFieldProps} />
					</Grid>
				))}
			</>
			<Grid item xs={12}>
				<Button variant="textUnderlined" navigateTo="/reset-password" disabled={loading}>
					{t('LoginView.actions.forgotPassword')}
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Button
					fullWidth
					onClick={handleLogin}
					variant="contained"
					loading={loading}
					triggerOnEnter
					style={{ minHeight: 48 }}
				>
					{t('LoginView.actions.loginButton')}
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Box width="100%" display="flex" flexDirection="row" alignItems="center">
					<Button m="0 1rem 0 0 " variant="textUnderlined" navigateTo="/signup" disabled={loading}>
						{t('LoginView.actions.createAccount')}
					</Button>
					<Button variant="textUnderlined" navigateTo="/phrase-login" disabled={loading}>
						{t('LoginView.actions.phraseLogin')}
					</Button>
				</Box>
			</Grid>

			{false && OIDC_ENDPOINT_URL && OIDC_CLIENT_ID ? (
				<>
					<Grid item xs={12}>
						<Box
							width="100%"
							display="flex"
							flexDirection="row"
							alignItems="center"
							paddingBottom="2rem"
						>
							<hr style={{ width: '100%', margin: '0 1rem  0 0' }} />
							<div>{t('LoginView.actions.or')}</div>
							<hr style={{ width: '100%', margin: '0 0 0 1rem ' }} />
						</Box>

						<Button
							fullWidth
							variant="outlined"
							onClick={handleSSOLogin}
							style={{ minHeight: 48 }}
						>
							<img src={nbpLogo} width={16} style={{ marginRight: 16, marginLeft: 16 }} />
							{t('LoginView.actions.loginWithOIDC')}
						</Button>
					</Grid>
				</>
			) : (
				<></>
			)}
		</AuthWrapper>
	);
};

export default LoginView;
