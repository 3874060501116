import React from 'react';

import {
	ContentWrapper,
	ImageHeader,
	Txt,
	SectionHeader,
	SectionWrapper,
	Logo,
	useTranslation,
} from '@eduvision/toolkit';
import image from '../images/BF_Grafik_FAQ_Kontakt.png';
import { CONTACT_EMAIL } from '../customization/consts';

const ContactView = () => {
	const { t } = useTranslation();
	return (
		<ContentWrapper>
			<ImageHeader
				title={t('ContactView.header.title')}
				markdownDescription={t('ContactView.header.markdownDescription',{mail:CONTACT_EMAIL})}
				src={image}
			/>
			<SectionWrapper flexDirection="column" alignItems="center">
				<SectionHeader alignItems="center" title={t('ContactView.details.title')} />
				<Logo m="2rem 0 0 0" />
				<Txt fontWeight="bold" m="2rem 0 0 0">
					{t('ContactView.details.contact')}
				</Txt>
				<Txt m="1rem 0 0 0">{CONTACT_EMAIL}</Txt>
				<Txt fontWeight="bold" m="2rem 0 0 0">
					{t('ContactView.details.address')}
				</Txt>
				<Txt m="1rem 0 0 0">visionYOU GmbH</Txt>
				<Txt>Stahnsdorfer Str. 107</Txt>
				<Txt>14482 Potsdam</Txt>
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default ContactView;
