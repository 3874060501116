import React from 'react';

import {
	ContentWrapper,
	ImageHeader,
	SectionWrapper,
	Contents,
	useCurrentUser,
	useTranslation,
} from '@eduvision/toolkit';
import teacherImage from '../images/BF_Header_FuerLehrer.png';

const LoggedInAccessPhraseUserView = (): JSX.Element | null => {
	const { t } = useTranslation();
	const currentUser = useCurrentUser();
	const accessPhraseContents = currentUser?.accessPhraseContents;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teacherImage}
				title={t('LoggedInAccessPhraseUserView.header.title')}
				description={t('LoggedInAccessPhraseUserView.header.description')}
			/>
			<SectionWrapper flexDirection="column">
				<Contents
					title={t('LoggedInAccessPhraseUserView.contents.title')}
					contents={accessPhraseContents}
					showProgress
				/>
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default LoggedInAccessPhraseUserView;
