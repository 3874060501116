export const ENVIRONMENT = import.meta.env.MODE
	? import.meta.env.MODE.toUpperCase()
	: 'DEVELOPMENT';
export const DEVELOPMENT = ENVIRONMENT === 'DEVELOPMENT';

export const BASE_URL = DEVELOPMENT 
	? 'http://localhost:3000' 
	: ENVIRONMENT === 'PRODUCTION'
	? 'https://bildungsfreaks.de'
	: ENVIRONMENT === 'STAGING'
	? 'https://frontendstage.bildungsfreaks.de'
	: 'wrong';

export const API_BASE_URL = DEVELOPMENT
	? 'http://localhost:5001'
	: ENVIRONMENT === 'PRODUCTION'
	? 'https://apilive.bildungsfreaks.de'
	: ENVIRONMENT === 'STAGING'
	? 'https://apistage.bildungsfreaks.de'
	: 'wrong';

console.log({
	ENVIRONMENT,
	DEVELOPMENT,
	API_BASE_URL,
});

export const API_BASE_URL_GQL = `${API_BASE_URL}/graphql`;
export const API_BASE_URL_SSO = `${API_BASE_URL}/sso`;

export const UPLOAD_CATEGORY_IMAGE_ENDPOINT = `${API_BASE_URL}/upload-category-image`;

export const COURSE_MATERIAL_CLOUDFRONT_URL = `${API_BASE_URL}/courses`;

export const CONTACT_EMAIL = 'kontakt@bildungsfreaks.de';

export const PAYPAL_CLIENT_ID =
	'AeGLpUTE3Txp16jXREo3PldIM-EgzMV7p_ibQTH1Z7T-gpZ0b7xo9MPjp9YPsCUQH8DvRNaRir8ytWbO';
export const PAYPAL_CURRENCY = 'USD';

//Make sure the text for this version is present in TermsOfServiceView.tsx
export const LATEST_TERMS_VERSION = 2; // This must be a integer (1,2,3...)

export const OIDC_ENDPOINT_URL =
	ENVIRONMENT === 'DEVELOPMENT' || ENVIRONMENT === 'STAGING'
		? 'https://aai.demo.meinbildungsraum.de/realms/nbp-aai/protocol/openid-connect'
		: undefined;
export const OIDC_CLIENT_ID =
	ENVIRONMENT === 'DEVELOPMENT' || ENVIRONMENT === 'STAGING'
		? 'e08bdeca-462c-4294-8914-459d1a6fb9e7'
		: undefined;

