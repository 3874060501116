import React from 'react';
import {
	Button,
	ContentWrapper,
	PaypalPayment,
	SearchSelectField2,
	Txt,
	useAllMemberships,
	useAllProducts,
	useSnackbar,
	useCurrentUser,
	useValidatePaypalSubscription,
	useCancelPaypalSubscription,
	useTranslation,
} from '@eduvision/toolkit';
import { Box } from '@mui/system';

const CheckoutView = () => {
	const snackBar = useSnackbar();
	const products = useAllProducts();
	const memberships = useAllMemberships();
	const currentUser = useCurrentUser();
	const validatePaypalSubscription = useValidatePaypalSubscription();
	const cancelPaypalSubscription = useCancelPaypalSubscription();
	const subscriptions = currentUser?.subscriptions;
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);

	const [productIds, setProductIds] = React.useState<string[]>([]);
	const handleChangeProducts = React.useCallback((items: { label: string; value?: string }[]) => {
		const ids = items.map(({ value }) => value) as string[];
		setProductIds(ids);
	}, []);

	const [membershipId, setMembershipId] = React.useState<string | undefined>(undefined);
	const handleChangeMembership = React.useCallback((items: { label: string; value?: string }[]) => {
		const id = !items.length ? undefined : items.map(({ value }) => value)[0];
		setMembershipId(id);
	}, []);

	const handleSuccess = React.useCallback(
		(paymentType: string) => {
			snackBar({ success: t('CheckoutView.paymentSuccess', { type: paymentType }) });
		},
		[snackBar]
	);

	const handleCheckSubscription = React.useCallback(
		async (id) => {
			setLoading(true);
			const { status } = await validatePaypalSubscription({ paymentId: id });
			snackBar({ info: t('CheckoutView.subscriptionStatus', { status }) });
			setLoading(false);
		},
		[snackBar]
	);

	const handleCancelSubscription = React.useCallback(
		async (id) => {
			setLoading(true);
			const { error } = await cancelPaypalSubscription({ paymentId: id });
			if (error) {
				setLoading(false);
				snackBar({ error: error });
				return;
			}
			snackBar({ info: t('CheckoutView.subscriptionCancelled') });
			setLoading(false);
		},
		[snackBar]
	);

	return (
		<ContentWrapper center p="1rem">
			{subscriptions?.length ? (
				<>
					<Txt>Subscriptions: </Txt>
					{subscriptions?.map((subscription, i) => (
						<Box key={i} display="flex" flexDirection="row" alignItems="center">
							<Txt m="0 0.5rem 0 0">{subscription}</Txt>
							<Button
								m="1rem"
								variant="outlined"
								id={subscription}
								onClick={handleCheckSubscription}
								loading={loading}
							>
								{t('CheckoutView.checkSubscription')}
							</Button>
							<Button
								variant="outlined"
								id={subscription}
								onClick={handleCancelSubscription}
								loading={loading}
							>
								{t('CheckoutView.cancelSubscription')}
							</Button>
						</Box>
					))}
				</>
			) : null}
			<SearchSelectField2
				label={t('CheckoutView.products')}
				options={products}
				onChange={handleChangeProducts}
				m="1rem"
				width="100%"
				multiple
				labelKey="title"
				valueKey="id"
				disabled={Boolean(membershipId)}
			/>
			<SearchSelectField2
				label={t('CheckoutView.memberships')}
				options={memberships}
				onChange={handleChangeMembership}
				m="1rem"
				width="100%"
				labelKey="title"
				valueKey="id"
				disabled={Boolean(productIds.length)}
			/>
			<PaypalPayment
				onSuccess={handleSuccess}
				productIds={productIds}
				membershipId={membershipId}
			/>
		</ContentWrapper>
	);
};

export default CheckoutView;
