import React from 'react';
import {
	useSearchParams,
	Grid,
	useNavigate,
	Button,
	Dialog,
	AuthWrapper,
	FilledTextField,
	useTextFieldController,
	checkValidationFunctions,
	validateEmail,
	validatePassword,
	useFetchUser,
	setToken,
	useInviteLogin,
	useTranslation,
} from '@eduvision/toolkit';
import { Box } from '@mui/material';

const InviteLoginView = (): JSX.Element => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const fetchUser = useFetchUser();
	const inviteLogin = useInviteLogin();
	const defaultEmail = searchParams.get('email') || '';
	const defaultPassword = searchParams.get('password') || '';

	const navigate = useNavigate();
	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: defaultEmail,
		inputLabel: t('InviteLoginView.input.email'),
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: defaultPassword,
		inputLabel: t('InviteLoginView.input.password'),
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const [loading, setLoading] = React.useState(false);

	const handleLogin = React.useCallback(async () => {
		const isValid = checkValidationFunctions([validateEmailFn, validatePasswordFn]);
		if (isValid) {
			setLoading(true);
			const { success, jwt, error } = await inviteLogin(email!, password!);
			setLoading(false);
			if (success) {
				setToken(jwt!);
				await fetchUser();
				navigate('/');
			} else {
				switch (error) {
					case 'USER_NOT_FOUND':
						Dialog.render(t('InviteLoginView.errors.USER_NOT_FOUND', { returnObjects: true }));
						break;
					case 'INVITED_USERS_ONLY':
						Dialog.render(t('InviteLoginView.errors.INVITED_USERS_ONLY', { returnObjects: true }));
						break;
					case 'CREDENTIALS_DO_NOT_MATCH':
						Dialog.render(
							t('InviteLoginView.errors.CREDENTIALS_DO_NOT_MATCH', { returnObjects: true })
						);
						break;
					case 'NETWORK_ERROR':
						Dialog.render(t('InviteLoginView.errors.NETWORK_ERROR', { returnObjects: true }));
						break;
					case 'INVITE_EXPIRED':
						Dialog.render(t('InviteLoginView.errors.INVITE_EXPIRED', { returnObjects: true }));
						break;
					default:
						Dialog.render(t('InviteLoginView.errors.UNKNOWN_ERROR', { returnObjects: true }));
				}
			}
		}
	}, [email, navigate, password, t, validateEmailFn, validatePasswordFn]);

	const textFieldsProps = React.useMemo(
		() => [emailFieldProps, passwordFieldProps],
		[emailFieldProps, passwordFieldProps]
	);

	const autoLoginRef = React.useRef<boolean>(false);
	React.useEffect(() => {
		if (defaultEmail && defaultPassword && autoLoginRef.current === false) {
			handleLogin();
			autoLoginRef.current = true;
		}
	}, [defaultEmail, defaultPassword, handleLogin]);

	return (
		<AuthWrapper title={t('InviteLoginView.login.title')}>
			<>
				{textFieldsProps.map((textFieldProps,i) => (
					<Grid key={i} item xs={12}>
						<FilledTextField fullWidth variant="filled" {...textFieldProps} />
					</Grid>
				))}
				<Grid item xs={12}>
					<Button variant="textUnderlined" navigateTo="/reset-password" disabled={loading}>
						{t('InviteLoginView.actions.forgotPassword')}
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button fullWidth onClick={handleLogin} variant="contained" loading={loading}>
						{t('InviteLoginView.actions.loginButton')}
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Box width="100%" display="flex" flexDirection="row" alignItems="center">
						<Button m="0 1rem 0 0 " variant="textUnderlined" navigateTo="/signup" disabled={loading}>
							{t('InviteLoginView.actions.createAccount')}
						</Button>
						<Button variant="textUnderlined" navigateTo="/phrase-login" disabled={loading}>
							{t('InviteLoginView.actions.phraseLogin')}
						</Button>
					</Box>
				</Grid>
			</>
		</AuthWrapper>
	);
};

export default InviteLoginView;
