/* eslint  no-useless-escape: 0 */
import React, { useEffect } from 'react';
import {
	Txt,
	Button,
	styled,
	gql,
	Dialog,
	useMutation,
	useSearchParams,
	useTranslation,
} from '@eduvision/toolkit';
import { Box, TextField } from '@mui/material';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

const reportAccessibilityProblemMutation = gql`
	mutation ReportAccessibilityProblem(
		$name: String
		$email: String
		$link: String!
		$description: String!
	) {
		reportAccessibilityProblem(
			name: $name
			email: $email
			link: $link
			description: $description
		) {
			success
		}
	}
	`;

const ReportAccessibilityProblemView = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [link, setLink] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [reportAccessibilityProblem] = useMutation(reportAccessibilityProblemMutation);
	const previousPage = searchParams.get('page');

	useEffect(() => {
		if (previousPage) {
			setLink(previousPage);
		}
	}, [previousPage]);

	const handleSubmission = async () => {
		console.log(name, email, link, description);
		if (!link || !description) {
			Dialog.render({
				title: t('ReportAccessibilityProblemView.errors.title'),
				description: t('ReportAccessibilityProblemView.errors.fillFields'),
			});
			return;
		}
		const { errors } = await reportAccessibilityProblem({
			variables: { name, email, link, description },
		});
		if (!errors) {
			setName('');
			setEmail('');
			setLink('');
			setDescription('');
			Dialog.render({
				title: t('ReportAccessibilityProblemView.success.title'),
				description: t('ReportAccessibilityProblemView.success.description'),
			});
		} else {
			Dialog.render({
				title: t('ReportAccessibilityProblemView.errors.title'),
				description: t('ReportAccessibilityProblemView.errors.submissionError'),
			});
		}
	};

	return (
		<Wrapper>
			<Txt variant="h2" fontWeight="bold" mt="2rem">
				{t('ReportAccessibilityProblemView.title')}
			</Txt>

			<Txt mt="2rem">{t('ReportAccessibilityProblemView.intro')}</Txt>
			<Txt mt="2rem" mb="2rem">
				{t('ReportAccessibilityProblemView.description')}
			</Txt>

			<TextField
				fullWidth
				variant="filled"
				label={t('ReportAccessibilityProblemView.input.name')}
				value={name}
				onChange={(event) => {
					setName(event.target.value);
				}}
			/>
			<Box mt="1rem">
				<TextField
					fullWidth
					variant="filled"
					label={t('ReportAccessibilityProblemView.input.email')}
					value={email}
					onChange={(event) => {
						setEmail(event.target.value);
					}}
				/>
			</Box>
			<Box mt="1rem">
				<TextField
					fullWidth
					variant="filled"
					label={t('ReportAccessibilityProblemView.input.link')}
					multiline={true}
					rows={3}
					value={link}
					onChange={(event) => {
						setLink(event.target.value);
					}}
				/>
			</Box>
			<Box mt="1rem">
				<TextField
					fullWidth
					variant="filled"
					label={t('ReportAccessibilityProblemView.input.description')}
					multiline={true}
					rows={5}
					value={description}
					onChange={(event) => {
						setDescription(event.target.value);
					}}
				/>
			</Box>
			<Txt mt="2rem">{t('ReportAccessibilityProblemView.confirmation')}</Txt>
			<Box mt="1rem">
				<Button variant="contained" onClick={handleSubmission}>
					{t('ReportAccessibilityProblemView.actions.submit')}
				</Button>
			</Box>
		</Wrapper>
	);
};

export default ReportAccessibilityProblemView;
