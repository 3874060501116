import React from 'react';
import {
	ContentWrapper,
	ImageHeader,
	Button,
	Dialog,
	SectionWrapper,
	useCurrentUser,
	useTranslation,
} from '@eduvision/toolkit';
import teacherImage from '../images/BF_Grafik_Module_BFGRATIS_Lehrer.png';
import CreateAccessGeneratorDialog from '../components/CreateAccessGeneratorDialog';
import AccessGeneratorsSection from '../components/AccessGeneratorsSection';

const ShareView = (): JSX.Element | null => {
	const { t } = useTranslation();
	const currentUser = useCurrentUser();

	const handleCreateAccessGenerator = React.useCallback(() => {
		Dialog.render({
			renderContent: CreateAccessGeneratorDialog,
			isLocked: true,
		});
	}, []);

	const remainingAccessPhrases = currentUser?.accessGeneratorsInfo?.remaining;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teacherImage}
				title={t('ShareView.title')}
				description={t('ShareView.description')}
			/>

			<SectionWrapper flexDirection="column">
				<Button
					variant="contained"
					onClick={handleCreateAccessGenerator}
					disabled={remainingAccessPhrases === 0}
				>
					{t('ShareView.actions.shareCourse')}
				</Button>
			</SectionWrapper>
			<AccessGeneratorsSection />
		</ContentWrapper>
	);
};
export default ShareView;
