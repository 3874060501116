import React from 'react';
import { Route, EducationFormatView } from '@eduvision/toolkit';

import AboutUsView from './views/AboutUsView';
import AccessGeneratorRegisterView from './views/AccessGeneratorRegisterView';
import CheckoutView from './views/CheckoutView';
import ContactView from './views/ContactView';
import FaqView from './views/FaqView';
import GetProView from './views/GetProView';
import InviteLoginView from './views/InviteLoginView';
import LoggedOutPupilView from './views/LoggedOutPupilView';
import LoggedOutTeacherView from './views/LoggedOutTeacherView';
import MembershipListView from './views/MembershipListView';
import OrderDetailView from './views/OrderDetailView';
import PaymentTermsView from './views/PaymentTerms';
import PhraseLoginView from './views/PhraseLoginView';
import ReportAccessibilityProblemView from './views/ReportAccessibilityProblemView';
import ShareView from './views/ShareView';
import SSOLoginView from './views/SSOLoginView';
import SSOLogoutView from './views/SSOLogoutView';
import TermsOfServiceView from './views/TermsOfServiceView';


import { ENVIRONMENT } from './customization/consts';

export const customRoutes: Route[] = [
	{
		path: '/pricing',
		element: <MembershipListView />,
		referenceInSitemap: true,
	},
	{
		path: '/about-us',
		element: <AboutUsView />,
		referenceInSitemap: true,
	},
	{
		path: '/access-generator-register/:accessGeneratorCode',
		element: <AccessGeneratorRegisterView />,
	},
	{
		path: '/access-generator-register',
		element: <AccessGeneratorRegisterView />,
	},
	{
		path: '/contact',
		element: <ContactView />,
		referenceInSitemap: true,
	},
	{
		path: '/faq',
		element: <FaqView />,
		referenceInSitemap: true,
	},
	{
		path: '/getpro/:productId/:userIsRegistered',
		element: <GetProView />,
	},
	{
		path: '/invite-login',
		element: <InviteLoginView />,
	},
	{
		path: '/pupils',
		element: <LoggedOutPupilView />,
		referenceInSitemap: true,
	},
	{
		path: '/teacher',
		element: <LoggedOutTeacherView />,
		referenceInSitemap: true,
	},
	{
		path: '/parents',
		element: <LoggedOutTeacherView />,
		referenceInSitemap: true,
	},
	{
		path: '/order/:id/:success',
		accessibility: ['LOGGED_IN'],
		element: <OrderDetailView />,
	},
	{
		path: '/payment-terms',
		element: <PaymentTermsView />,
	},
	{
		path: '/phrase-login',
		element: <PhraseLoginView />,
	},
	{
		path: '/accessibility-problem',
		element: <ReportAccessibilityProblemView />,
	},
	{
		path: '/share',
		accessibility: ['LOGGED_IN'],
		element: <ShareView />,
	},
	{
		path: '/sso-login',
		element: <SSOLoginView />,
		referenceInSitemap: false,
	},
	{
		path: '/sso-logout',
		element: <SSOLogoutView />,
		referenceInSitemap: false,
	},
	{
		path: '/terms/:version?',
		element: <TermsOfServiceView />,
	},
	{
		path: '/training',
		element: <EducationFormatView educationFormatId="6" />,
		referenceInSitemap: true,
	},
	{
		path: '/downloads',
		element: <EducationFormatView educationFormatId="8" />,
		referenceInSitemap: true,
	},
	{
		path: '/checklists',
		element: <EducationFormatView educationFormatId="9" />,
		referenceInSitemap: true,
	},
	{
		path: '/teaching-program',
		element: <EducationFormatView educationFormatId="7" />,
		referenceInSitemap: true,
	},
	{
		path: '/interactive-courses',
		element: <EducationFormatView educationFormatId="5" />,
		referenceInSitemap: true,
	},
	// PAYPAL_TODO: remove once feature is ready
	...(ENVIRONMENT === 'PRODUCTION'
		? []
		: [
				{
					path: '/checkout',
					accessibility: ['LOGGED_IN'],
					element: <CheckoutView />,
				} as Route,
		  ]),
];
