import React from 'react';
import {
	useParams,
	useNavigate,
	useApiFilteredContents,
	useContentDetails,
	useContentInfoBoxContent,
	useRecommendedApiContents,
	ContentWrapper,
	IconButton,
	Dialog,
	ContentHeader,
	Contents,
	useCurrentUser,
	useSnackbar,
	useContentButtonProps,
	SectionWrapper,
	FavouriteIcon,
	useTranslation,
	ContentDetails,
} from '@eduvision/toolkit';
import { Helmet } from 'react-helmet';
import ProBannerSmallSection from '../components/ProBannerSmallSection';

const ContentDetailView = ({
	contentData,
	isPreview,
}: {
	contentData?: ContentDetails;
	isPreview?: boolean;
}): JSX.Element | null => {
	const navigate = useNavigate();
	const snackBar = useSnackbar();
	const { t } = useTranslation();

	const currentUser = useCurrentUser();

	const isAccessPhraseUser = currentUser?.isAccessPhraseUser;

	const { id } = useParams();
	const { data: fetchedContent, loading: contentIsLoading } = useContentDetails(isPreview || contentData ? undefined : id);
	const content = contentData || fetchedContent;
	const infoBoxContent = useContentInfoBoxContent(content, isPreview);
	const contentButtonProps = useContentButtonProps(content, isPreview);
	const relatedSerie = content?.collections?.find(
		(c) => content?.serieOrder?.[0]?.collectionId && c.id === content.serieOrder[0].collectionId
	);
	const relatedSerieId = relatedSerie?.id;
	const firstRelatedCollection = content?.collections?.[0];
	const firstRelatedCollectionId = firstRelatedCollection?.id;

	const contentIsForbidden = !isPreview &&
		((content && content.publicationStatus !== 'PUBLISHED') ||
			content?.accessibilities?.length === 0 ||
			content?.deletedAt ||
			(!contentIsLoading && !content)
		);

	const { data: relatedContents } = useApiFilteredContents({
		contentIsContainedInCollectionIds: relatedSerieId
			? [relatedSerieId]
			: firstRelatedCollectionId
			? [firstRelatedCollectionId]
			: undefined,
		contentIsActive: true,
		contentIsContainedInProducts: true,
		contentIsDemo: false,
		skip: Boolean(!relatedSerieId && !firstRelatedCollectionId),
		excludeContentIds: id ? [id] : undefined,
	});

	//  Sort related contents by serieOrder
	const sortedRelatedContents = React.useMemo(() => {
		const sortCopy = relatedContents ? [...relatedContents] : [];
		return sortCopy.sort((a, b) =>{
			const aSerieOrder = a.serieOrder?.find((s)=>s.collectionId===relatedSerieId);
			const bSerieOrder = b.serieOrder?.find((s)=>s.collectionId===relatedSerieId);

			return (aSerieOrder?.order && bSerieOrder?.order
				? aSerieOrder?.order > bSerieOrder?.order
					? 1
					: -1
				: aSerieOrder?.order
				? -1
				: bSerieOrder?.order
				? 1
				: 0);
			});
	}, [relatedContents,relatedSerieId]);

	const relatedCategoryId = relatedSerie?.id || firstRelatedCollection?.id;

	const recommendedContents = useRecommendedApiContents(currentUser?.audience?.id);

	const handleCopyToClipboard = React.useCallback(() => {
		const link = window.location.href;
		navigator.clipboard.writeText(link);
		snackBar({ success: t('ContentDetailView.linkCopied') });
	}, [snackBar,t]);

	const renderContent = React.useCallback(() => {
		return (
			<>
				<FavouriteIcon contentId={id!} m="0 2rem 0 0" />
				<IconButton iconName="Share" onClick={handleCopyToClipboard} m="0 2rem 0 0" />
			</>
		);
	}, [handleCopyToClipboard, id]);

	React.useEffect(() => {
		if (contentIsForbidden) {
			Dialog.render({
				title: t('ContentDetailView.inactiveContent.title'),
				description: t('ContentDetailView.inactiveContent.description'),
				isLocked: true,
				buttons: [
					{
						id: '1',
						label: t('ContentDetailView.inactiveContent.button'),
						onClick: () => navigate('/'),
					},
				],
			},'inactive');
		}
	}, [content, navigate, t, isPreview, contentIsLoading, contentIsForbidden]);

	return (
		<ContentWrapper>
			{!content || !contentButtonProps || !sortedRelatedContents || contentIsForbidden ? null : (
				<>
					<Helmet>
						<title>
							{t('ContentDetailView.title', {
								contentTitle: content.title || '',
							})}
						</title>
						<meta name="description" content={content.metaDescription || content.description} />
					</Helmet>
					<ContentHeader
						title={content.title}
						shortDescription={content.shortDescription}
						author={content.author}
						buttonText={contentButtonProps.label}
						isButtonHidden={contentButtonProps.isHidden}
						onClick={contentButtonProps.onClick}
						imageUrl={content.imageUrl}
						imageAlt={content.imageAlt}
						descriptionTitle={t('ContentDetailView.header.descriptionTitle')}
						description={content.description}
						infoBoxTitle={t('ContentDetailView.header.infoBoxTitle')}
						infoBoxContent={infoBoxContent!}
						renderContent={renderContent}
						cooperations={content.cooperations}
						accessibilities={content.accessibilities}
						learningGoals={content.learningGoals}
						why={content.why}
						who={content.who}
						what={content.what}
						techRequirements={content.techRequirements}
						requirements={content.requirements}
						structure={content.structure}
					/>
					{!isAccessPhraseUser ? (
						<>
							{sortedRelatedContents?.length ? (
								<SectionWrapper>
									<Contents
										contents={sortedRelatedContents}
										title={
											relatedSerie
												? t('ContentDetailView.relatedContents.completeSeries')
												: t('ContentDetailView.relatedContents.completeCollection')
										}
										navigateTo={`/collection/${relatedCategoryId}`}
										buttonLabel={t('ContentDetailView.relatedContents.button')}
										openNewWindow={isPreview}
									/>
								</SectionWrapper>
							) : null}
							<SectionWrapper>
								<Contents
									contents={recommendedContents}
									title={t('ContentDetailView.recommendedContents.title')}
									maxItems={6}
									navigateTo="/contents"
									buttonLabel={t('ContentDetailView.recommendedContents.button')}
									openNewWindow={isPreview}
								/>
							</SectionWrapper>
							<ProBannerSmallSection />
						</>
					) : null}
				</>
			)}
		</ContentWrapper>
	);
};

export default ContentDetailView;
