import React from 'react';
import {BottomAppBar, TopAppBar, useLocation, useDivDimensions, appBarHeightVar, useCurrentUser} from '@eduvision/toolkit';
import { AppBar as MuiAppBar, Box } from '@mui/material';
import AccessPhraseUserAppBar from './AccessPhraseUserAppBar';

const AppBar = () => {
	const location = useLocation();
	const isPlayerRoute = location.pathname.includes('/player/');
	const currentUser = useCurrentUser();
	const ref = React.useRef<HTMLDivElement>(null);

	const deps = React.useMemo(() => [location.pathname], [location.pathname]);
	const { height } = useDivDimensions(ref, 'resize', deps);
	appBarHeightVar(height);

	return isPlayerRoute ? null : (
		<>
			<Box>
				<MuiAppBar position="fixed" ref={ref}>
					{currentUser?.isAccessPhraseUser ? <AccessPhraseUserAppBar /> : <TopAppBar />}
					{currentUser?.isAccessPhraseUser ? null : <BottomAppBar />}
				</MuiAppBar>
				<Box width="100%" height={height} />
			</Box>
		</>
	);
};

export default React.memo(AppBar);
