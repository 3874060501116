import React from 'react';
import {
	Grid,
	useNavigate,
	useSnackbar,
	useTextFieldController,
	Button,
	GridContainer,
	Dialog,
	Txt,
	CheckBox,
	CheckBoxRef,
	EmailVerificator,
	SelectField,
	SelectFieldRef,
	useRegister,
	validateEmail,
	validatePassword,
	Audience,
	useAllAudiences,
	useTheme
} from '@eduvision/toolkit';
import { TextField, CircularProgress, Box } from '@mui/material';
import { LATEST_TERMS_VERSION } from '../customization/consts';

const defaultGridItemProps = {
	xs: 12,
	sm: 6,
	item: true,
};

const horizontalSpacing = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };
const verticalSpacing = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };

const RegisterForm = ({
	lockUserType,
	onRegisterSuccess,
	hideHeader,
	gridItemProps,
	buttonLabel,
	defaultConfirmationCode,
	defaultEmail,
	contentId,
}: {
	lockUserType?: boolean;
	onRegisterSuccess?: (email: string) => void;
	hideHeader?: boolean;
	gridItemProps?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
	buttonLabel?: string;
	defaultConfirmationCode?: string;
	defaultEmail?: string;
	contentId?: string;
}): JSX.Element => {
	const theme = useTheme();
	const navigate = useNavigate();
	const snackBar = useSnackbar();
	const register = useRegister();
	const currentGridItemProps = React.useMemo(
		() => gridItemProps || defaultGridItemProps,
		[gridItemProps]
	);

	const audienceCategories = useAllAudiences();
	const userTypeSelectOptions = React.useMemo(
		() => audienceCategories?.map((cat) => ({ label: cat.title, value: cat.id })),
		[audienceCategories]
	);

	const [processStep, setProcessStep] = React.useState<'ENTER_INFOS' | 'ENTER_CONFIRMATION_CODE'>(
		defaultConfirmationCode ? 'ENTER_CONFIRMATION_CODE' : 'ENTER_INFOS'
	);

	const {
		newStateValue: firstName,
		textInputProps: firstNameFieldProps,
		validate: validateFirstNameFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Vorname',
		stateKey: 'firstName',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const {
		newStateValue: lastName,
		textInputProps: lastNameFieldProps,
		validate: validatelastNameFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Nachname',
		stateKey: 'lastName',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Email',
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Passwort',
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const {
		newStateValue: organizationName,
		textInputProps: organizationNameFieldProps,
		validate: validateorganizationNameFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Organisation',
		stateKey: 'organizationName',
		validationFunction: (value) => (!value ? 'Bitte ausfüllen' : undefined),
		stateValueFallback: '',
	});

	const { newStateValue: userName, textInputProps: userNameFieldProps } = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Nutzername (Optional)',
		stateKey: 'userName',
		stateValueFallback: '',
	});

	const [loading, setLoading] = React.useState<boolean>(false);
	const [audienceCategory, setAudienceCategory] = React.useState<undefined | Audience>(undefined);
	const [termsAccepted, setTermsAccepted] = React.useState<boolean>(false);

	const handleChangeAudienceCategoryId = React.useCallback(
		(audCatId) => {
			const category = audienceCategories?.find((cat) => cat.id === audCatId);
			setAudienceCategory(category);
		},
		[audienceCategories]
	);

	const handleToggleTermsAccepted = React.useCallback((newValue) => {
		setTermsAccepted(newValue);
	}, []);

	const userTypeSelectFieldRef = React.useRef<SelectFieldRef>(null);
	const checkBoxRef = React.useRef<CheckBoxRef>(null);

	const handleValidate = React.useCallback(() => {
		const errors = [
			validateFirstNameFn!(),
			validatelastNameFn!(),
			validateEmailFn!(),
			validatePasswordFn!(),
			userTypeSelectFieldRef.current?.validate?.(),
			audienceCategory?.type === 'TEACHER' ? validateorganizationNameFn!() : null,
			checkBoxRef?.current?.validate?.(),
		];
		const invalid = errors.some(Boolean);
		return invalid;
	}, [
		validateFirstNameFn,
		validatelastNameFn,
		validateEmailFn,
		validatePasswordFn,
		audienceCategory,
		validateorganizationNameFn,
	]);

	const handleRegister = React.useCallback(async () => {
		const invalid = handleValidate();
		if (!invalid) {
			setLoading(true);
			const { success, error } = await register({
				firstName: firstName!,
				lastName: lastName!,
				email: email!,
				password: password!,
				audienceId: audienceCategory!.id,
				organizationName: organizationName || undefined,
				userName: userName || undefined,
				termsAcceptedVersion: LATEST_TERMS_VERSION!,
			});
			setLoading(false);
			if (success) {
				Dialog.render({
					title: 'Bestätigungs-Email versendet!',
					description:
						'Wir haben dir soeben eine Email mit einem Bestätigungscode geschickt. Bitte trage diesen innerhalb der nächsten Stunde im nächsten Schritt ein.',
					onClose: () => setProcessStep('ENTER_CONFIRMATION_CODE'),
					buttons: [
						{ id: '1', label: 'Ok', onClick: () => setProcessStep('ENTER_CONFIRMATION_CODE') },
					],
				});
			} else {
				snackBar({ error });
			}
		}
	}, [
		handleValidate,
		firstName,
		lastName,
		email,
		password,
		audienceCategory,
		organizationName,
		userName,
		snackBar,
	]);

	const handleEmailVerificationSuccess = React.useCallback(async () => {
		if (onRegisterSuccess) {
			onRegisterSuccess(email!);
		} else {
			snackBar({ success: 'Eingeloggt!' });
			contentId ? navigate(`/content/${contentId}`) : navigate('/');
		}
	}, [contentId, email, navigate, onRegisterSuccess, snackBar]);

	const handleEmailVerificationError = React.useCallback(async () => {
		setProcessStep('ENTER_INFOS');
	}, []);

	const textFieldsProps = React.useMemo(
		() => [firstNameFieldProps, lastNameFieldProps, emailFieldProps, passwordFieldProps],
		[emailFieldProps, firstNameFieldProps, lastNameFieldProps, passwordFieldProps]
	);

	const inputProps = React.useMemo(
		() => ({
			style: {
				backgroundColor: theme.palette.common.white,
			},
		}),
		[theme.palette.common.white]
	);

	return (
		<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
			{hideHeader ? null : (
				<Grid item xs={12}>
					<Txt variant="h2" fontWeight="bold">
						Kostenlos registrieren
					</Txt>
				</Grid>
			)}
			{processStep === 'ENTER_INFOS' ? (
				<>
					<>
						{textFieldsProps.map((textFieldProps,i) => (
							<Grid key={i} {...currentGridItemProps}>
								<TextField fullWidth variant="filled" {...textFieldProps} InputProps={inputProps} />
							</Grid>
						))}
					</>

					<Grid {...currentGridItemProps}>
						{userTypeSelectOptions ? (
							<SelectField
								defaultValue={audienceCategory?.id}
								disabled={lockUserType}
								backgroundColor={theme.palette.common.white}
								fullWidth
								label="Ich bin..."
								items={userTypeSelectOptions}
								onChange={handleChangeAudienceCategoryId}
								ref={userTypeSelectFieldRef}
								showSearch={false}
							/>
						) : (
							<CircularProgress />
						)}
					</Grid>
					{audienceCategory?.type === 'TEACHER' ? (
						<Grid {...currentGridItemProps}>
							<TextField
								fullWidth
								variant="filled"
								{...organizationNameFieldProps}
								InputProps={inputProps}
							/>
						</Grid>
					) : null}
					<Grid {...currentGridItemProps}>
						<TextField fullWidth variant="filled" {...userNameFieldProps} InputProps={inputProps} />
					</Grid>
					<Grid item xs={12}>
						<CheckBox
							defaultValue={termsAccepted}
							isMarkdown
							size="small"
							label="Hiermit bestätige ich, dass ich den [Nutzungsbedingungen](/terms_blank) von bildungsfreaks zustimme."
							onChange={handleToggleTermsAccepted}
							ref={checkBoxRef}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							onClick={handleRegister}
							variant="contained"
							loading={loading}
							triggerOnEnter
						>
							{buttonLabel || 'Registrieren'}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" flexDirection="row" alignItems="center">
							<Button m="0 1rem 0 0" navigateTo="/login" variant="textUnderlined" disabled={loading}>
								Bereits angemeldet?
							</Button>
							<Button navigateTo="/access-generator-register" variant="textUnderlined" disabled={loading}>
								Mit Code registrieren?
							</Button>
						</Box>
					</Grid>
				</>
			) : processStep === 'ENTER_CONFIRMATION_CODE' ? (
				<EmailVerificator
					onSuccess={handleEmailVerificationSuccess}
					emailAddress={defaultEmail || email!}
					onError={handleEmailVerificationError}
					defaultConfirmationCode={defaultConfirmationCode}
				/>
			) : null}
		</GridContainer>
	);
};

export default RegisterForm;
