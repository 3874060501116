import React from 'react';
import { useCurrentUser, LoggedInUserView } from '@eduvision/toolkit';
import LoggedInAccessPhraseUserView from './LoggedInAccessPhraseUserView';

const LoggedInView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();
	
	return !currentUser ? null : currentUser.isAccessPhraseUser ? (
		<LoggedInAccessPhraseUserView />
	) : (
		<LoggedInUserView />
	);
};

export default LoggedInView;
