import React from 'react';
import {
	useNavigate,
	Button,
	Grid,
	Dialog,
	AuthWrapper,
	FilledTextField,
	useTextFieldController,
	useFetchUser,
	setToken,
	useTranslation,
} from '@eduvision/toolkit';
import {usePhraseLogin} from '../mutations/usePhraseLogin';

const PhraseLoginView = (): JSX.Element => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const phraseLogin = usePhraseLogin();
	const fetchUser = useFetchUser();
	const {
		newStateValue: phrase,
		textInputProps: textFieldProps,
		validate: validate,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: t('PhraseLoginView.input.label'),
		stateKey: 'phrase',
		validationFunction: (val) => (!val ? t('PhraseLoginView.input.validationMessage') : undefined),
		stateValueFallback: '',
		textFieldType: 'phrase',
	});

	const [loading, setLoading] = React.useState(false);

	const handleLogin = React.useCallback(async () => {
		const invalid = validate!();
		if (invalid) {
			return;
		}

		setLoading(true);
		const { success, jwt, error } = await phraseLogin(phrase!);
		setLoading(false);
		if (success) {
			setToken(jwt!);
			await fetchUser();
			navigate('/');
		} else {
			switch (error) {
				case 'USER_NOT_FOUND':
					Dialog.render(t('PhraseLoginView.errors.USER_NOT_FOUND', { returnObjects: true }));
					break;
				case 'NETWORK_ERROR':
					Dialog.render(t('PhraseLoginView.errors.NETWORK_ERROR', { returnObjects: true }));
					break;
				default:
					Dialog.render(t('PhraseLoginView.errors.UNKNOWN_ERROR', { returnObjects: true }));
			}
		}
	}, [navigate, phrase, t, validate]);

	return (
		<AuthWrapper title={t('PhraseLoginView.title')}>
			<>
				<Grid item xs={12}>
					<FilledTextField fullWidth variant="filled" {...textFieldProps} />
				</Grid>

				<Grid item xs={12}>
					<Button fullWidth onClick={handleLogin} variant="contained" loading={loading}>
						{t('PhraseLoginView.actions.loginButton')}
					</Button>
				</Grid>
			</>
		</AuthWrapper>
	);
};

export default PhraseLoginView;
