/*eslint promise/prefer-await-to-then: 0 */

import React, { useEffect } from 'react';
import {
	useNavigate,
	useSearchParams,
	gql,
	styled,
	Txt,
	Button,
	useMutation,
	useFetchUser,
	setToken,
	useTranslation,
	setssoIdToken,
} from '@eduvision/toolkit';

const ErrorWrapper = styled.div`
	margin: 4rem;
`;

const ssoLoginMutation = gql`
	mutation ssoLogin($code: String!, $sessionId: String!) {
		ssoLogin(code: $code, sessionId: $sessionId) {
			jwt
			ssoIdToken
		}
	}
`;

const SSOLoginView = (): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const code = searchParams.get('code') || undefined;
	const sessionId = searchParams.get('session_state') || undefined;
	const state = searchParams.get('state') || undefined;
	const contentId = state?.startsWith('content_') ? state.split('_')[1] : undefined;
	const [hasErrors, setHasErrors] = React.useState(false);
	const fetchUser = useFetchUser();
	const [ssoLogin] = useMutation(ssoLoginMutation);

	useEffect(() => {
		const doLogin = async (code: string, sessionId: string) => {
			const { errors, data } = await ssoLogin({
				variables: { code, sessionId },
			});
			if (errors) {
				console.error(errors);
				setHasErrors(true);
				return;
			}
			setToken(data.ssoLogin.jwt!);
			setssoIdToken(data.ssoLogin.ssoIdToken!);
			await fetchUser();
			contentId ? navigate(`/content/${contentId}`) : navigate('/');
		};

		if (code && sessionId) {
			doLogin(code, sessionId).catch((e) => {
				console.error(e);
			});
		}
	}, [code, sessionId]);

	if (!code || hasErrors) {
		return (
			<ErrorWrapper>
				<Txt variant="h2">{t('SSOLoginView.error.title')}</Txt>
				<Txt pt={3}>{t('SSOLoginView.error.description')}</Txt>
				<Button navigateTo="/login">{t('SSOLoginView.error.retry')}</Button>
			</ErrorWrapper>
		);
	}

	return <>{t('SSOLoginView.loading')}</>;
};

export default SSOLoginView;
