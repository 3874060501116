import React from 'react';
import {
	useSnackbar,
	ContentWrapper,
	OrderSummary,
	useUserOrder,
	useParams,
	useTranslation,
} from '@eduvision/toolkit';

const OrderDetailView = (): JSX.Element => {
	const { t } = useTranslation();
	const { id, success: successString } = useParams();
	const success = successString === 'true';
	const order = useUserOrder(id);
	const snackBar = useSnackbar();

	React.useEffect(() => {
		if (success) {
			snackBar({ success: t('OrderDetailView.successMessage') });
		}
	}, [snackBar, success, t]);

	return (
		<ContentWrapper maxWidth="100%">
			{!order ? null : (
				<OrderSummary p="2rem" priceLabel={order.priceLabel} products={order.products} />
			)}
		</ContentWrapper>
	);
};

export default OrderDetailView;
