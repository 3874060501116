
import React from 'react';
import {Navigate,Route} from '@eduvision/toolkit';

// type Accessibility = 'LOGGED_OUT' | 'LOGGED_IN';

export const oldAppReroutes: Route[] = [
	{
		path: '/collections/interaktive-lerneinheiten/sophie-scholl',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/10" replace={true} />,
	},
	{
		path: '/collections/sophie-scholl',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/10" replace={true} />,
	},
	{
		path: '/collections/diversity',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/17" replace={true} />,
	},
	{
		path: '/collections/svipe',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/15" replace={true} />,
	},
	{
		path: '/collections/berufsorientierung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/16" replace={true} />,
	},
	{
		path: '/collections/politische-medienkompetenz',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/13" replace={true} />,
	},
	{
		path: '/collections/wissensbissen',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/11" replace={true} />,
	},
	{
		path: '/products/datenschutz-sensibilisierung-schulen-bundeslaenderspezifisch',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/19" replace={true} />,
	},
	{
		path: '/products/visiontour-1-multimediale-berufsorientierung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/12" replace={true} />,
	},
	{
		path: '/products/medienentwicklungsplan-vorlage-schulentwicklung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/" replace={true} />,
	},
	{
		path: '/products/the-life-of-sophie-scholl',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/content/103" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-das-leben-der-sophie-scholl',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/content/1" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-fakt-vs-fiktion-wie-filme-geschichte-transportieren',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/content/2" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-filmanalyse-die-beziehung-zwischen-bild-und-ton',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/content/3" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-die-figuren-um-sophie-scholl-analyse-der-verschiedenen-beziehungen',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/content/4" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-handreichung-fur-lehrkrafte',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/content/5" replace={true} />,
	},
	{
		path: '/privacy',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/data-privacy" replace={true} />,
	},
	/////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////
	//SERIES
	{
		path: '/category/27',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/10" replace={true} />,
	},
	{
		path: '/category/27-sophie-scholl',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/10" replace={true} />,
	},
	{
		path: '/category/28',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/14" replace={true} />,
	},
	{
		path: '/category/28-entrepreneurship',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/14" replace={true} />,
	},
	{
		path: '/category/29',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/13" replace={true} />,
	},
	{
		path: '/category/29-politische-medienkompetenz',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/13" replace={true} />,
	},
	/////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////
	//COLLECTIONS
	{
		path: '/category/10',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/10" replace={true} />
	},
	{
		path: '/category/10-sophie-scholl',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/10" replace={true} />
	},
	{
		path: '/category/12',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/12" replace={true} />
	},
	{
		path: '/category/12-vision-tour',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/12" replace={true} />
	},
	{
		path: '/category/13',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/13" replace={true} />
	},
	{
		path: '/category/13-politische-medienkompetenz',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/13" replace={true} />
	},
	{
		path: '/category/14',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/14" replace={true} />
	},
	{
		path: '/category/14-entrepreneurship',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/14" replace={true} />
	},
	{
		path: '/category/15',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/15" replace={true} />
	},
	{
		path: '/category/15-svipe',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/15" replace={true} />
	},
	{
		path: '/category/16',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/16" replace={true} />
	},
	{
		path: '/category/16-berufsorientierung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/16" replace={true} />
	},
	{
		path: '/category/17',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/17" replace={true} />
	},
	{
		path: '/category/17-diversity',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/17" replace={true} />
	},
	{
		path: '/category/19',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/19" replace={true} />
	},
	{
		path: '/category/19-datenschutz-fuer-schulen',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/19" replace={true} />
	},
	{
		path: '/category/20',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/20" replace={true} />
	},
	{
		path: '/category/20-medienbildung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/20" replace={true} />
	},
	{
		path: '/category/21',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/21" replace={true} />
	},
	{
		path: '/category/21-bildung-fuer-nachhaltige-entwicklung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/21" replace={true} />
	},
	{
		path: '/category/11',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/11" replace={true} />
	},
	{
		path: '/category/11-wissensbissen',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/collection/11" replace={true} />
	},
	/////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////
	// EDUCATION FORMATS
	{
		path: '/category/5',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/5" replace={true} />
	},
	{
		path: '/category/5-interaktive-lerneinheiten',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/5" replace={true} />
	},
	{
		path: '/category/6',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/6" replace={true} />
	},
	{
		path: '/category/6-fortbildung',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/6" replace={true} />
	},
	{
		path: '/category/8',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/8" replace={true} />
	},
	{
		path: '/category/8-materialien',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/8" replace={true} />
	},
	{
		path: '/category/9',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/9" replace={true} />
	},
	{
		path: '/category/9-checklisten-und-vorlagen',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/9" replace={true} />
	},
	{
		path: '/category/7',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/7" replace={true} />
	},
	{
		path: '/category/7-unterrichtsprogramme',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-format/7" replace={true} />
	},
	/////////////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////////////////////
	// EDUCATION LEVELS
	{
		path: '/category/22',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-level/22" replace={true} />
	},
	{
		path: '/category/22-sek-i',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-level/22" replace={true} />
	},
	{
		path: '/category/23',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-level/23" replace={true} />
	},
	{
		path: '/category/23-sek-ii',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-level/23" replace={true} />
	},
	{
		path: '/category/24',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-level/24" replace={true} />
	},
	{
		path: '/category/24-primarstufe',
		accessibility: ['LOGGED_IN'],
		element: <Navigate to="/education-level/24" replace={true} />
	},
];



