import React from 'react';
import {
	useSearchParams,
	Grid,
	useAllMemberships,
	styled,
	useCurrentUser,
	GridItem,
	Button,
	Txt,
	ContentWrapper,
	SectionWrapper,
	MembershipFeatures,
	GetProContactDialog,
	Dialog,
	useNavigate,
	useTranslation,
} from '@eduvision/toolkit';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

const OrangeCell = styled(Box)<{ $widthFactor: number; $backgroundColor?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: ${(p) => p.theme.palette.secondary.main};
	border-radius: 0.5rem;
	height: 3rem;
`;

const gridItemsPropsDict = {
	1: {
		xs: 12,
		sm: 12,
		md: 12,
		lg: 12,
		xl: 12,
	},
	2: {
		xs: 12,
		sm: 6,
		md: 6,
		lg: 6,
		xl: 6,
	},
	3: {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 4,
		xl: 4,
	},
};

const StyledGridItem = styled(GridItem)`
	background-color: ${(p) => p.theme.palette.background.panel};
	border-radius: 0.5rem;
`;

const MembershipListView = (): JSX.Element | null => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const membershipIds = searchParams.get('membershipIds')?.split(',');
	const allMemberships = useAllMemberships();
	const currentUser = useCurrentUser();

	const handleShowGetProContactDialog = React.useCallback(() => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => <GetProContactDialog dismissPortal={dismissPortal} />,
		});
	}, []);

	const currentMembershipLevel = currentUser?.membership?.level;
	const maxMembershipLevel = allMemberships?.reduce(
		(acc, { level }) => (level > acc ? level : acc),
		0
	);

	const relevantMemberships = React.useMemo(
		() =>
			allMemberships
				?.filter(({ id }) => (membershipIds?.length ? membershipIds.includes(id) : true))
				.filter(({ level }) => {
					return !currentMembershipLevel
						? true
						: level === maxMembershipLevel
						? true
						: level > currentMembershipLevel;
				}),
		[allMemberships, currentMembershipLevel, maxMembershipLevel, membershipIds]
	);

	const relevantMembershipsWithButtonProps = React.useMemo(
		() =>
			relevantMemberships?.map((membership) => {
				return {
					...membership,
					button: {
						label:
							currentUser?.membership?.id === membership.id
								? undefined
								: membership.type === 'LEVEL_1'
								? t('MembershipListView.buttons.register')
								: t('MembershipListView.buttons.becomePro'),
						onClick:
							currentUser?.membership?.id === membership.id
								? undefined
								: membership.type === 'LEVEL_1'
								? () => navigate('/signup')
								: handleShowGetProContactDialog,
					},
				};
			}),
		[currentUser?.membership?.id, handleShowGetProContactDialog, navigate, relevantMemberships, t]
	);

	return (
		<ContentWrapper>
			<Helmet>
				<meta name="description" content={t('MembershipListView.meta.description')} />
			</Helmet>
			<SectionWrapper>
				<Box display="flex" flexDirection="row" justifyContent="center" width="100%">
					<Box maxWidth="70rem">
						<Grid container spacing={2}>
							{relevantMembershipsWithButtonProps?.map(
								({ id, title, description, priceLabel, appFunctionalities, button }) => {
									return (
										<StyledGridItem
											key={id}
											{...gridItemsPropsDict[relevantMembershipsWithButtonProps?.length]}
											flexDirection="column"
											justifyContent="flex-start"
											alignItems="flex-start"
										>
											<OrangeCell>
												<Txt variant="h3">{title}</Txt>
											</OrangeCell>
											<Box p="2rem" width="100%" height="8rem">
												<Txt variant="body1">{description}</Txt>
												<Txt m="1rem 0 0 0" variant="h3">
													{priceLabel}
												</Txt>
											</Box>
											<Box p="2rem" flex="1" display="flex" flexDirection="column">
												<MembershipFeatures appFunctionalities={appFunctionalities} />
												<Box flex="1" />
												{button.label && button.onClick ? (
													<Button
														m="2rem 0 0 0"
														onClick={button.onClick}
														fullWidth
														variant="contained"
													>
														{button.label}
													</Button>
												) : null}
											</Box>
										</StyledGridItem>
									);
								}
							)}
						</Grid>
					</Box>
				</Box>
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default MembershipListView;
