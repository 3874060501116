import React from 'react';
import {
	styled,
	useNavigate,
	Grid,
	Button,
	Logo,
	useLogout,
	useCurrentUser,
	GridContainer,
} from '@eduvision/toolkit';
import { Box, Toolbar, BoxProps } from '@mui/material';

const StyledToolBar = styled(Toolbar)`
	background-color: ${(p) => p.theme.palette.background.panel};
	min-height: 5rem;
`;

const horizontalSpacing = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 };
const verticalSpacing = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 };

const gridConfig = {
	item: true,
	xs: 6,
	sm: 6,
	md: 6,
	lg: 6,
};

const GridItem = ({
	children,
	xs,
	sm,
	md,
	lg,
	xl,
	...rest
}: {
	children: JSX.Element;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
} & BoxProps) => {
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<Box
				width="100%"
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				{...rest}
			>
				{children}
			</Box>
		</Grid>
	);
};

const AccessPhraseUserAppBar = () => {
	const navigate = useNavigate();
	const logout = useLogout();
	const currentUser = useCurrentUser();
	const handleLogout = React.useCallback(() => {
		logout();
	}, [logout]);
	return (
		<StyledToolBar>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<GridItem {...gridConfig} justifyContent="flex-start">
					<Logo navigateTo="/" />
				</GridItem>
				<GridItem {...gridConfig} justifyContent="flex-end">
					<Button variant="outlined" color="secondary" onClick={handleLogout} m="0 1rem 0 0">
						Abmelden
					</Button>
				</GridItem>
			</GridContainer>
		</StyledToolBar>
	);
};

export default React.memo(AccessPhraseUserAppBar);
