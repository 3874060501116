/* eslint  no-useless-escape: 0 */
import React from 'react';
import { styled, useTranslation } from '@eduvision/toolkit';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

const PaymentTermsView = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<ReactMarkdown>{t('PaymentTermsView.paymentText')}</ReactMarkdown>
		</Wrapper>
	);
};

export default PaymentTermsView;
