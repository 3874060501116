import React from 'react';
import {
	Grid,
	Button,
	Txt,
	styled,
	ImageBox,
	GridItem,
	SectionWrapper,
	useTranslation
} from '@eduvision/toolkit';
import { Box } from '@mui/material';
import image from '../images/BF_Grafik_Vorteile_Home_v3.png';

const Column = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const StyledText = styled(Txt)`
	color: ${(p) => p.theme.palette.primary.main};
`;


type SectionItem = {
	title: string;
	description: string;
	navigateTo: string;
};

const TargetGroupsSection = () => {
	const { t } = useTranslation();
	const items = (t('TargetGroupsSection.items', { returnObjects: true }) as SectionItem[]);
	return (
		<SectionWrapper altBackground>
			<Grid container>
				<GridItem xs={12} sm={12} md={7}>
					<ImageBox objectFit="contain" maxHeight="40rem" width="100%" src={image} />
				</GridItem>
				<GridItem xs={12} sm={12} md={5}>
					<Box flexDirection="column" p="2rem">
						{items.map(({ title, description, navigateTo },i) => (
							<Column key={i}>
								<StyledText variant="h3">{title}</StyledText>
								<Txt variant="body2" m="1rem 0">
									{description}
								</Txt>
								<Button m="0 0 2rem" endIconName="ChevronRight" navigateTo={navigateTo}>
									{t('TargetGroupsSection.more')}
								</Button>
							</Column>
						))}
					</Box>
				</GridItem>
			</Grid>
		</SectionWrapper>
	);
};

export default React.memo(TargetGroupsSection);
