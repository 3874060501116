import React from 'react';
import {
	SectionWrapper,
	ImageHeader,
	ContentWrapper,
	Contents,
	CategoriesRow,
	EducationFormatSection,
	SectionHeader,
	useAllEducationLevels,
	useAllCollections,
	useCurrentUser,
	useNavigate,
	useRecommendedApiContents,
	useTranslation,
	useAllAudiences
} from '@eduvision/toolkit';
import ProBannerSection from '../components/ProBannerSection';
import { Helmet } from 'react-helmet';
import image from '../images/BF_Header_FuerLehrer.png';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';

const LoggedOutTeacherView = (): JSX.Element | null => {
	const { t } = useTranslation();
	const currentUser = useCurrentUser();
	const navigate = useNavigate();

	const educationLevelCategories = useAllEducationLevels();
	const {data:collectionCategories} = useAllCollections();

	// React.useEffect(() => {
	// 	if (currentUser?.audience && currentUser.audience.type !== 'TEACHER') {
	// 		navigate('/');
	// 	}
	// }, [currentUser, navigate]);
	const allAudiences = useAllAudiences();
	const teacherAudience = allAudiences?.find((audience)=>(audience.type==='TEACHER'));
	const recommendedContents = useRecommendedApiContents(teacherAudience?.id);

	return currentUser === undefined ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<Helmet>
				<meta name="description" content={t('LoggedOutTeacherView.meta.description')} />
			</Helmet>
			<ImageHeader
				src={image}
				title={t('LoggedOutTeacherView.header.title')}
				description={t('LoggedOutTeacherView.header.description')}
			/>
			<SectionWrapper>
				<Contents
					contents={recommendedContents}
					buttonLabel={t('LoggedOutTeacherView.contents.favorites.buttonLabel')}
					navigateTo="/contents/TEACHER"
					title={t('LoggedOutTeacherView.contents.favorites.title')}
					subTitle={t('LoggedOutTeacherView.contents.favorites.subTitle')}
					maxItems={6}
				/>
			</SectionWrapper>

			<EducationFormatSection />
			<SectionWrapper flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title={t('LoggedOutTeacherView.sections.makeItYourThing.title')}
					subTitle={t('LoggedOutTeacherView.sections.makeItYourThing.subTitle')}
				/>
				<CategoriesRow data={educationLevelCategories || undefined} m="0 0 1rem 0" />
				<CategoriesRow data={collectionCategories || undefined} />
			</SectionWrapper>
			<ProBannerSection />
			<BildungsfreaksFavouritesSection />
		</ContentWrapper>
	);
};

export default LoggedOutTeacherView;
