import {Customization} from '@eduvision/toolkit';
import interactiveSrc from '../images/BF_Grafik_Kategorie_Interaktive_Lerneinheiten.png';
import trainingSrc from '../images/BF_Grafik_Kategorie_Fortbildungen.png';
import templatesSrc from '../images/BF_Grafik_Kategorie_Checklisten_Vorlagen.png';
import worksheetSrc from '../images/BF_Grafik_Kategorie_Materialien.png';
import projectSrc from '../images/BF_Grafik_Kategorie_Unterrichtsprogramme.png';

export const EducationFormatSection: Customization['EducationFormatSection'] = {
  imageDict: {
    interactive:{
      pos:1,
      imageSrc: interactiveSrc
    },
    training:{
      pos:2,
      imageSrc: trainingSrc
    },
    templates:{
      pos:3,
      imageSrc: templatesSrc
    },
    worksheet:{
      pos:4,
      imageSrc: worksheetSrc
    },
    project:{
      pos:5,
      imageSrc: projectSrc
    }
  }
};