import React from 'react';
import {
	useParams,
	ContentWrapper,
	Logo,
	Grid,
	styled,
	RegisterForm,
	Txt,
	OrderSummary,
	GridContainer,
	Button,
	useProduct,
	useAppState,
	useStandardAddress,
	AddressForm,
	useActiveBreakPoint,
	useSnackbar,
	useTranslation,
} from '@eduvision/toolkit';
import Box from '@mui/material/Box';

type ProcessStep = 'REGISTER' | 'ADDRESS' | 'PAYMENT';
const allProcessSteps: ProcessStep[] = ['REGISTER', 'ADDRESS', 'PAYMENT'];
const registeredUserProcessSteps: ProcessStep[] = ['ADDRESS', 'PAYMENT'];
type ProcessStepDict = {
	REGISTER: string;
	ADDRESS: string;
	PAYMENT: string;
};

const Header = ({
	userIsRegistered,
	processStep,
}: {
	userIsRegistered?: boolean;
	processStep: ProcessStep;
}) => {
	const { t } = useTranslation();
	const processStepDict = t('GetProView.processStep', {
		returnObjects: true,
	}) as ProcessStepDict;
	const index = userIsRegistered
		? registeredUserProcessSteps.findIndex((p) => p === processStep)
		: allProcessSteps.findIndex((p) => p === processStep);
	const stepNumber = index + 1;

	return (
		<Txt m="2rem 2rem 0 2rem" fontWeight="bold" variant="h3">
			{stepNumber}. {processStepDict[processStep]}
		</Txt>
	);
};

const CIRCLE_SIZE = 1;
const LINE_WIDTH = 10;
const LABEL_HEIGHT = 1;

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const Circle = styled(Box)<{ $active?: boolean }>`
	width: ${CIRCLE_SIZE}rem;
	height: ${CIRCLE_SIZE}rem;
	border-radius: ${CIRCLE_SIZE / 2}rem;
	border: 1px solid ${(p) => p.theme.palette.primary.main};
	background-color: ${(p) => (p.$active ? p.theme.palette.primary.main : 'transparent')};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Line = styled(Box)`
	width: ${LINE_WIDTH}rem;
	border-bottom: 1px solid ${(p) => p.theme.palette.grey[100]};
`;

const InvisibleLine = styled(Box)`
	width: ${LINE_WIDTH / 2}rem;
	border-bottom: 1px solid transparent;
`;

const AbsolutePosition = styled(Box)`
	left: ${CIRCLE_SIZE / 2}rem;
	position: absolute;
	width: ${LINE_WIDTH}rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translate(-50%, 0);
	height: ${LABEL_HEIGHT}rem;
`;

const ProcessRoute = ({
	processStep,
	userIsRegistered,
}: {
	processStep: ProcessStep;
	userIsRegistered?: boolean;
}) => {
	const { t } = useTranslation();
	const processStepDict = t('GetProView.processStep', {
		returnObjects: true,
	}) as ProcessStepDict;
	const activeBreakPonint = useActiveBreakPoint();
	const steps = React.useMemo(() => {
		const processSteps = userIsRegistered ? registeredUserProcessSteps : allProcessSteps;
		return processSteps.map((ps) => ({ label: processStepDict[ps], active: processStep === ps }));
	}, [processStep, userIsRegistered]);

	const showLabel = React.useMemo(
		() => ['sm', 'md', 'lg', 'xl'].includes(activeBreakPonint),
		[activeBreakPonint]
	);

	return (
		<Row alignItems="flex-start" height={`${LABEL_HEIGHT + CIRCLE_SIZE}rem`}>
			<Row alignItems="center" width="100%">
				<InvisibleLine />
				{steps.map(({ label, active }, index, arr) => (
					<React.Fragment key={index}>
						<Box position="relative">
							<Circle $active={active} />
							<AbsolutePosition>
								<Txt m="2.5rem 0 0 0" variant="body2">
									{showLabel ? label : index + 1}
								</Txt>
							</AbsolutePosition>
						</Box>

						{index === arr.length - 1 ? null : <Line />}
					</React.Fragment>
				))}
				<InvisibleLine />
			</Row>
		</Row>
	);
};

const BackgroundColumn = styled(Box)`
	display: flex;
	flex-direction: column;
	background-color: ${(p) => p.theme.palette.background.panel};
	border-radius: 1rem;
`;

const gridItemProps = {
	xs: 12,
	sm: 12,
	md: 12,
	lg: 6,
	item: true,
};

const registerFormGridItemProps = {
	xs: 12,
	sm: 12,
	md: 12,
	lg: 6,
	item: true,
};

const GetProView = (): JSX.Element => {
	const snackBar = useSnackbar();
	const { productId: productIdString, userIsRegistered: userIsRegisteredString } = useParams();
	const { t } = useTranslation();

	const productId = productIdString === 'undefined' ? undefined : productIdString;
	const userIsRegistered = userIsRegisteredString === 'true';

	const product = useProduct(productId);
	const products = React.useMemo(() => (product ? [product] : []), [product]);

	const standardAddress = useStandardAddress();
	const appState = useAppState();

	const [processStep, setProcessStep] = React.useState<ProcessStep>(
		appState==='LOGGED_IN' ? 'ADDRESS' : 'REGISTER'
	);

	const handleRegisterSuccess = React.useCallback(() => {
		setProcessStep('ADDRESS');
	}, []);

	const handleSaveAdressSuccess = React.useCallback(() => {
		setProcessStep('PAYMENT');
	}, []);

	const handleFakePayment = React.useCallback(() => {
		snackBar({ info: t('GetProView.notImplemented') });
	}, [snackBar, t]);

	return (
		<ContentWrapper maxWidth="100%">
			{!product || standardAddress === undefined ? null : (
				<GridContainer>
					<Grid item xs={12}>
						<ProcessRoute processStep={processStep} userIsRegistered={userIsRegistered} />
					</Grid>
					<Grid {...gridItemProps}>
						<BackgroundColumn>
							<Box p="2rem 2rem 0 2rem">
								<Logo />
							</Box>
							<Header processStep={processStep} userIsRegistered={userIsRegistered} />
							{processStep === 'REGISTER' ? (
								<RegisterForm
									hideHeader
									buttonLabel={t('GetProView.continue')}
									lockUserType={true}
									onRegisterSuccess={handleRegisterSuccess}
									gridItemProps={registerFormGridItemProps}
								/>
							) : processStep === 'ADDRESS' ? (
								<AddressForm
									id={standardAddress?.id}
									defaultFederalState={standardAddress?.federalState}
									defaultCity={standardAddress?.city}
									defaultZipCode={standardAddress?.zipCode}
									defaultStreet={standardAddress?.street}
									defaultHouseNumber={standardAddress?.houseNumber}
									onSuccess={handleSaveAdressSuccess}
								/>
							) : processStep === 'PAYMENT' ? (
								<Button m="2rem" variant="contained" onClick={handleFakePayment}>
									{t('GetProView.pay')}
								</Button>
							) : null}
						</BackgroundColumn>
					</Grid>
					<Grid {...gridItemProps}>
						<BackgroundColumn p="2rem">
							<OrderSummary priceLabel={product.priceLabel} products={products} />
						</BackgroundColumn>
					</Grid>
				</GridContainer>
			)}
		</ContentWrapper>
	);
};

export default GetProView;
