import React from 'react';
import {
	useApiFilteredContents,
	styled,
	Button,
	ImageHeader,
	ContentWrapper,
	SectionHeader,
	PerksSection,
	SectionWrapper,
	Contents,
	CategoriesRow,
	PublicStatisticsBar,
	DemosSection,
	useAllCollections,
	useTranslation,
	useAllAudiences,
	useNavigate
} from '@eduvision/toolkit';
import { Box } from '@mui/material';
import ProBannerSection from '../components/ProBannerSection';
import image from '../images/BF_Header_Home_Zuschnitt.png';
import bmbfLogo from '../images/BMBF_gefoerdert_vom_deutsch.jpg';
import euLogo from '../images/DE_Funded_by_NextGenEU_vert_RGB_POS.png';
import TargetGroupsSection from '../components/TargetGroupsSection';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';

const Relative = styled(Box)`
	position: relative;
`;

const LoggedOutView = (): JSX.Element => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const renderContent = React.useCallback(() => {
		return (
			<Button navigateTo="/pricing" m="1rem 0 0" variant="contained">
				{t('LoggedOutView.header.buttonLabel')}
			</Button>
		);
	}, [t]);

	const {data:priorityContents} = useApiFilteredContents({ contentHasPriority: true, contentIsDemo:false });

	const {data:collectionCategories} = useAllCollections();
	const allAudiences = useAllAudiences();

	const handleContentShowMoreClick = React.useCallback( (selectedAudienceId) => {
		const selectedAudience = allAudiences?.find((audience)=>audience.id===selectedAudienceId);
		const link = `/contents/${selectedAudience?.type}`;	
		navigate(link);
	},[navigate,allAudiences]);

	return (
		<ContentWrapper>
			<Relative>
				<ImageHeader
					height={40}
					src={image}
					title={t('LoggedOutView.header.title')}
					subTitle={t('LoggedOutView.header.subTitle')}
					renderContent={renderContent}
				/>
				<PublicStatisticsBar />
			</Relative>
			<Box m="0 0 1.5rem 0" />
			<SectionWrapper>
				<Contents
					filterAfterCategoryGroup="AUDIENCE"
					title={t('LoggedOutView.contents.topCourses.title')}
					subTitle={t('LoggedOutView.contents.topCourses.subTitle')}
					contents={priorityContents}
					onShowMoreClick={handleContentShowMoreClick}
					maxItems={6}
					buttonLabel={t('LoggedOutView.contents.topCourses.buttonLabel')}
				/>
			</SectionWrapper>
			<SectionWrapper altBackground padding="2rem" flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title={t('LoggedOutView.sections.futureTopics.title')}
					subTitle={t('LoggedOutView.sections.futureTopics.subTitle')}
				/>
				<CategoriesRow data={collectionCategories || undefined} />
			</SectionWrapper>
			<PerksSection />
			<ProBannerSection />
			<BildungsfreaksFavouritesSection />
			<TargetGroupsSection />
			<DemosSection />
			<SectionWrapper
				style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '20px' }}
			>
				<img src={bmbfLogo} alt="BMBF Logo" width={238} />
				<img src={euLogo} alt="Funded by EU" width={140} />
			</SectionWrapper>
		</ContentWrapper>
	);
};

export default LoggedOutView;
