import React from 'react';
import {
	ImageHeader,
	ContentWrapper,
	Contents,
	IWantMoreSection,
	useRecommendedApiContents,
	SectionWrapper,
	useTranslation,
	useAllAudiences
} from '@eduvision/toolkit';
import { Helmet } from 'react-helmet';
import image from '../images/BF_Grafik_Module_BFGRATIS_Schueler.png';

const LoggedOutPupilView = (): JSX.Element => {
	const { t } = useTranslation();
	const allAudiences = useAllAudiences();
	const pupilAudience = allAudiences?.find((audience)=>(audience.type==='PUPILS'));
	const recommendedContents = useRecommendedApiContents(pupilAudience?.id);
	return (
		<ContentWrapper>
			<Helmet>
				<meta name="description" content={t('LoggedOutPupilView.meta.description')} />
			</Helmet>
			<ImageHeader
				src={image}
				title={t('LoggedOutPupilView.header.title')}
				description={t('LoggedOutPupilView.header.description')}
			/>
			<SectionWrapper>
				<Contents
					title={t('LoggedOutPupilView.contents.best.title')}
					subTitle={t('LoggedOutPupilView.contents.best.subTitle')}
					contents={recommendedContents}
					navigateTo="/contents/PUPILS"
					maxItems={6}
					buttonLabel={t('LoggedOutPupilView.contents.best.buttonLabel')}
				/>
			</SectionWrapper>

			<IWantMoreSection />
		</ContentWrapper>
	);
};

export default LoggedOutPupilView;
