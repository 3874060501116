/* eslint  no-useless-escape: 0 */

import React from 'react';
import { useParams, styled, useTranslation } from '@eduvision/toolkit';
import ReactMarkdown from 'react-markdown';
import { LATEST_TERMS_VERSION } from '../customization/consts';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

const TermsOfServiceView = () => {
	const { version } = useParams();
	const { t } = useTranslation();
	const versions = t('TermsOfServiceView.versions', {
		returnObjects: true,
	}) as Record<string, string>;
	let versionText: string;

	if (version && Object.keys(versions).includes(version)) {
		versionText = versions[version];
	} else {
		versionText = versions[String(LATEST_TERMS_VERSION)];
	}
	return (
		<Wrapper>
			<ReactMarkdown>{versionText}</ReactMarkdown>
		</Wrapper>
	);
};

export default TermsOfServiceView;
