import React from 'react';
import {
	Button,
	GridItem,
	Txt,
	ImageBox,
	GridContainer,
	SectionWrapper,
	useCurrentUser,
	useTranslation
} from '@eduvision/toolkit';
import image from '../images/Pro_Badge.png';

const horizontalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const gridItemProps = {
	xs: 12,
	sm: 4,
	md: 4,
};

const ProBannerSmallSection = () => {
	const {t} = useTranslation();
	const currentUser = useCurrentUser();
	const hasProMembership = currentUser?.membership?.level === 2;
	return hasProMembership || currentUser?.audienceType === 'PUPILS' ? null : (
		<SectionWrapper altBackground>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<GridItem {...gridItemProps}>
					<ImageBox height="10rem" objectFit="contain" src={image} />
				</GridItem>
				<GridItem {...gridItemProps}>
					<Txt m="1rem">
						{t('ProBannerSmallSection.info')}
					</Txt>
				</GridItem>
				<GridItem {...gridItemProps}>
					<Button navigateTo="/pricing" m="1rem" variant="contained">
					{t('ProBannerSmallSection.button')}
					</Button>
				</GridItem>
			</GridContainer>
		</SectionWrapper>
	);
};

export default React.memo(ProBannerSmallSection);
