import React from 'react';
import {Customization} from '@eduvision/toolkit';
import BildungsfreaksIcon from '../components/BildungsfreaksIcon';
const showPricing = (currentUser)=>(!currentUser || (currentUser && (currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS')));

export const navigation: Customization['navigation'] = {
  desktopNavButtons: [
    (location,currentUser)=>({
      key: 'pricing',
      label: 'NavButtons.pro',
      navigateTo: '/pricing',
      variant: 'text',
      isVisible: showPricing(currentUser),
      pos:2
    }),
    {
			key: 'about',
			label: 'NavButtons.aboutUs',
			navigateTo: '/about-us',
			variant: 'text',
      pos:3
		}
  ],
  mobileNavButtons: [
    {
			key: 'about',
			label: 'NavButtons.aboutUs',
			navigateTo: '/about-us',
			variant: 'text',
      pos:3
		},
    (location,currentUser)=>({
      key: 'pricing',
			label: 'NavButtons.pro',
			navigateTo: '/pricing',
			variant: 'text',
			icon: <BildungsfreaksIcon />,
      isVisible: showPricing(currentUser),
			pos:4
		}),
    {
      key: 'faq',
			label: 'NavButtons.faq',
			navigateTo: '/faq',
			variant: 'text',
			iconName: 'InfoOutlined',
			pos:5
		},
    (location,currentUser)=>({
      key: 'accessibility-problem',
			label: 'NavButtons.accessibilityProblem',
			navigateTo: '/accessibility-problem?page=' + encodeURIComponent(location.pathname + location.search),
			variant: 'text',
			iconName: 'InfoOutlined',
			pos:7
		}),
  ]
};
